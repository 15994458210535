/* eslint-disable react/prop-types */
import React from 'react';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { OrderTrigger } from 'types/enums';
import { ResearchPairResponse } from 'types/research/research-pair.response';

interface TradeOrderDescriptionProps {
  type: OrderTrigger | null;
  isBuy?: boolean;
  isStopLoss?: boolean;
  token: ResearchPairResponse;
  isDown: boolean;
  diff: number;
}

const TradeOrderIntlDescription = ({
  props,
}: {
  props: TradeOrderDescriptionProps;
}) => {
  const { type, isBuy, isStopLoss, token, isDown, diff } = props;
  const {
    i18n: { language },
  } = useTranslation();
  const l = (language: string) => {
    if ('ru-RU'.includes(language)) return 'ru-RU';
    if ('en-EN'.includes(language)) return 'en-EN';
  };

  const shouldBeLess = isStopLoss && !isBuy && !isDown || !isStopLoss && isBuy && !isDown;
  const shouldBeMore = isStopLoss && isBuy && isDown || !isStopLoss && !isBuy && isDown;

  if (shouldBeLess || shouldBeMore) {
    const error: { [locale: string]: JSX.Element } = {
      'en-EN': (
        <span className='tx-danger mx-1'>
          The{' '}
          {type === OrderTrigger.MCAP ? 'market capitalization ' : 'price '}
          in trigger should be{' '}
          {shouldBeLess && 'less '}
          {shouldBeMore && 'more '}
          than current one.
        </span>
      ),
      'ru-RU': (
        <span className='tx-danger mx-1'>
          {type === OrderTrigger.MCAP ? 'Рыночная капитализация ' : 'Цена '}
          в триггере должна быть{' '}
          {shouldBeLess && 'меньше '}
          {shouldBeMore && 'больше '}
          чем текущая.
        </span>
      ),
    };

    return (
      <div className="tx-muted tx-13 mt-3">{error[l(language) ?? 'en-EN']}</div>
    );
  }

  const desc: { [locale: string]: JSX.Element } = {
    'en-EN': (
      <>
        Will
        <span className={`${isBuy ? 'tx-success' : 'tx-danger'} mx-1`}>
          {isBuy ? 'buy' : 'sell'}
        </span>
        {token.quoteToken.symbol} after its
        <span className="mx-1">
          {type === OrderTrigger.MCAP ? 'market capitalization' : 'price'}
        </span>
        goes {isDown ? 'down' : 'up'}
        <span className={`${!isDown ? 'tx-success' : 'tx-danger'} ms-1`}>
          {new BigNumber(diff).toFixed(2, 1)}%
        </span>
      </>
    ),
    'ru-RU': (
      <>
        <span className={`${isBuy ? 'tx-success' : 'tx-danger'} me-1`}>
          {isBuy ? 'Купить' : 'Продать'}
        </span>
        {token.quoteToken.symbol}, когда его
        <span className="mx-1">
          {type === OrderTrigger.MCAP ? 'рыночная капитализация' : 'цена'}
        </span>
        {isDown ? 'упадет' : 'вырастет'} на
        <span className={`${!isDown ? 'tx-success' : 'tx-danger'} ms-1`}>
          {new BigNumber(diff).toFixed(2, 1)}%
        </span>
      </>
    ),
  };

  return (
    <div className="tx-muted tx-13 mt-3">{desc[l(language) ?? 'en-EN']}</div>
  );
};

export default TradeOrderIntlDescription;