import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { reaction, IReactionOptions } from 'mobx';

export function effect<T>(
  dependicles: () => T[],
  effect: (currentValue: T[], previousValue: T[]) => any,
  options?: IReactionOptions<string, any>,
) {
  const depsCb = () => JSON.stringify(dependicles());
  const effectCb = (c: string, p: string) =>
    effect(...([c, p].map((o) => JSON.parse(o)) as [T[], T[]]));
  return reaction<string>(depsCb, effectCb, options);
}

export function debouncedReaction<T>(
  expression: () => T,
  effect: (value: T, previousValue: T) => void,
  delay: number = 300,
) {
  let previousValue: T;

  return reaction(
    expression,
    debounce((currentValue, p) => {
      if (!isEqual(previousValue, currentValue)) {
        previousValue = currentValue;
        effect(currentValue, p);
      }
    }, delay),
  );
}
