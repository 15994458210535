import {
  action,
  computed,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import * as Sentry from '@sentry/react';
import api, { apiRaw, Paged } from '@helpers/api';
import { WalletBalance } from '../types';
import { ChainId } from '../constants';
import { CreateOrderRequest } from '../types/order/create-order.request';
import { OrderResponse } from '../types/order/order.response';
import { OrdersSummaryResponse } from '../types/order/orders-summary.response';
import { TokenBalanceResponse } from '../types/order/token-balance.response';
import { OrderType } from '../types/order/order.enum';
import { CommandResultResponse } from '../types/command-result.response';
import { SocketStore } from './socket-store';
import { AccountStore } from '@stores/account-store';
import { CreateOrderResponse } from '../types/order/create-order.response';
import { WalletType } from '../types/enums';
import { hasOwnProperty } from '@helpers/object';
import { UpdateOrderRequest } from '../types/order/update-order.request';
import { AxiosError } from 'axios';
import { WalletStore } from '@stores/wallet-store';
import { getUpdatedSummary } from '@helpers/priceUpdate';

const keys = ['_network'];

enum Events {
  SUBSCRIBE_PRICE_UPDATE = 'SUBSCRIBE_PRICE_UPDATE',
  UNSUBSCRIBE_PRICE_UPDATE = 'UNSUBSCRIBE_PRICE_UPDATE',
}

export class OrdersStore {
  private _socketStore: SocketStore;
  private _accountStore: AccountStore;
  private _walletStore: WalletStore;
  private _loadBalanceController?: AbortController;

  @observable
  private _initialized = false;

  @observable
  private _isSaving = false;

  @observable
  private _triggerUpdate = 0;

  @observable
  private _balance: WalletBalance | null = null;

  @observable
  private _summary: OrdersSummaryResponse[] = [];

  @observable
  private _isLoadingSummary = false;

  @observable
  private _hideDetails = false;

  constructor(
    socketStore: SocketStore,
    accountStore: AccountStore,
    walletStore: WalletStore,
  ) {
    this._socketStore = socketStore;
    this._accountStore = accountStore;
    this._walletStore = walletStore;

    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => accountStore.currentWallet,
      () => {
        this.loadBalance();
        this.loadSummary();
      },
    );

    reaction(
      () => accountStore.network,
      () => {
        this.loadBalance();
        this.handleUpdate();
        this.loadSummary();
      },
    );
  }

  @computed
  profile() {
    return this._accountStore.currentWallet;
  }

  @computed
  get socket() {
    return this._socketStore.socket;
  }

  @computed
  get isInit() {
    return (
      this.profile() !== null &&
      this._accountStore.isLoggedIn &&
      this.initialized
    );
  }

  @computed
  get isEvm() {
    return this.profile()?.type === WalletType.EVM;
  }

  @computed
  get isTon() {
    return this.profile()?.type === WalletType.TON;
  }

  @computed
  get isTron() {
    return this.profile()?.type === WalletType.TRON;
  }

  @computed
  get isSolana() {
    return this.profile()?.type === WalletType.SOL;
  }

  @computed
  get isSui() {
    return this.profile()?.type === WalletType.SOL;
  }

  @computed
  get currentOrderType() {
    return this.profile()?.type;
  }

  @computed
  get network() {
    return this._accountStore.network;
  }

  @action.bound
  async loadBalance(reset = true) {
    if (!this.isInit) return;

    if (reset) {
      this._balance = null;
    }

    this._loadBalanceController?.abort();
    this._loadBalanceController = new AbortController();

    return apiRaw<WalletBalance>({
      method: 'get',
      path: `/balance/native`,
      signal: this._loadBalanceController.signal,
      data: {
        walletId: this.profile()?.id,
        blockchain: this.network === ChainId.ALL ? ChainId.ETHER : this.network,
      },
    })
      .then((response) => {
        this._balance = response.data || null;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          // Обработка кейса с запросом баланса неверного блокчейна
          if (response.response.status === 500) {
            this._accountStore.resetWalletData();

            Sentry.captureEvent(
              { message: 'Wallet ID Error' },
              { data: { response, source: 'orders-store' } },
            );
          }
        } else {
          console.error(response);
        }
      });
  }

  @computed
  get balance() {
    return this._balance;
  }

  @computed
  get summary() {
    return this._summary;
  }

  @computed
  get isLoadingSummary() {
    return this._isLoadingSummary;
  }

  @computed
  get isSaving() {
    return this._isSaving;
  }

  @computed
  get triggerUpdate() {
    return this._triggerUpdate;
  }

  @computed
  get initialized() {
    return this._initialized;
  }

  @computed
  get hideDetails() {
    return this._hideDetails;
  }

  @action.bound
  setHideDetails(value: boolean) {
    this._hideDetails = value;
    this.handleUpdate();
  }

  @action.bound
  handleUpdate() {
    this._triggerUpdate++;
    this._isSaving = true;
  }

  @action.bound
  setSaved() {
    this._isSaving = false;
  }

  @action.bound
  async getTokenBalance(tokenAddress: string, blockchain?: ChainId) {
    if (!this.isInit) return;

    this._balance = null;

    return api<TokenBalanceResponse>({
      method: 'get',
      path: `/balance/token`,
      data: {
        walletId: this.profile()?.id,
        tokenAddress,
        blockchain:
          blockchain || this.network === ChainId.ALL
            ? ChainId.ETHER
            : this.network,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
      });
  }

  @action.bound
  async loadOrders(pairId: string, filter?: string, type?: OrderType[]) {
    if (!this.isInit) return;

    const types = type ? type.map((v) => `type=${v}`).join('&') : undefined;

    return api<Paged<OrderResponse[]>>({
      method: 'get',
      path: `/order?${types ? '&' + types : ''}`,
      data: {
        blockchain: this.network,
        walletId: this.profile()?.id,
        pairId,
        filter,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action.bound
  async getOrder(id: string) {
    return api<OrderResponse>({
      method: 'get',
      path: `/order/${id}`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action.bound
  async loadSummary(seamless = false) {
    if (!this.isInit || this.isLoadingSummary) return;

    if (!seamless) {
      this._isLoadingSummary = true;
    }

    return api<OrdersSummaryResponse[]>({
      method: 'get',
      path: `/order/summary`,
      data: {
        blockchain: this.network,
        walletId: this.profile()?.id,
      },
    })
      .then((response) => {
        this._summary = response;
        this._isLoadingSummary = false;
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          if (response instanceof AxiosError && response.response?.data) {
            console.error(response.response.data);
          } else {
            console.error(response);
          }
        }
        this._isLoadingSummary = false;
        return response.response;
      });
  }

  @action.bound
  async loadHiddenSummary() {
    if (!this.isInit) return;

    return api<OrdersSummaryResponse[]>({
      method: 'get',
      path: `/order/summary/hidden`,
      data: {
        blockchain: this.network,
        walletId: this.profile()?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action.bound
  async createOrder(data: CreateOrderRequest) {
    try {
      return api<CreateOrderResponse>({
        method: 'post',
        path: `/order`,
        data,
      })
        .then((response) => {
          this._walletStore.loadPortfolio();
          this._walletStore.loadBalance();
          return response;
        })
        .catch((response) => {
          if (
            response instanceof AxiosError &&
            response.response?.status === 401
          ) {
            this._accountStore.logout();
          }
          if (response instanceof AxiosError && response.response?.data) {
            console.error(response.response.data);
          } else {
            console.error(response);
          }
          return response.response;
        });
    } catch (e) {
      console.log('Create order error', e);
    }
  }

  @action.bound
  async updateOrder(data: UpdateOrderRequest) {
    return api<OrderResponse>({
      method: 'patch',
      path: `/order`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this._accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action.bound
  async cancelOrder(id: string) {
    return api<CommandResultResponse>({
      method: 'post',
      path: `/order/cancel`,
      data: {
        orderId: id,
      },
    })
      .then((response) => {
        return response.success;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action.bound
  async hideSummary(pairId: string, hidden: boolean) {
    return apiRaw<CommandResultResponse>({
      method: 'post',
      path: `/preferences/wallet/pair`,
      data: {
        walletId: this.profile()?.id,
        pairId,
        hidden,
      },
    })
      .then((response) => {
        return response.status === 201;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @computed
  get state() {
    return keys.map((key) => {
      return {
        key,
        // @ts-ignore
        value: key === '_network' ? this.network : this[key],
      };
    });
  }

  @action.bound
  restoreSettings(keys: string[], values: string[]) {
    keys.forEach((key, index) => {
      try {
        const value = values[index] ? JSON.parse(values[index]) : null;
        if (
          hasOwnProperty(this, key) &&
          value &&
          // @ts-ignore
          JSON.stringify(this[key]) !== JSON.stringify(value)
        ) {
          // @ts-ignore
          this[key] = value;
        } else if (key === '_network' && value) {
          this._accountStore.setNetwork(value);
        }
      } catch (e) {
        // console.log(e);
      }
    });
    this._initialized = true;
  }

  @action.bound
  async subscribePrice() {
    if (this.summary) {
      this.socket?.on(Events.SUBSCRIBE_PRICE_UPDATE, (data) => {
        runInAction(
          () =>
            (this._summary = this.summary.map((i) =>
              i.pairAddress.toLowerCase() === data.pairAddress.toLowerCase()
                ? getUpdatedSummary(i, data)
                : i,
            )),
        );
      });

      this.socket?.emit(Events.SUBSCRIBE_PRICE_UPDATE, {
        blockchain: this.network,
        pairAddresses: this.summary.map(({ pairAddress }) => pairAddress),
      });
    }
  }

  @action.bound
  async unsubscribePrice() {
    this.socket?.emit(Events.UNSUBSCRIBE_PRICE_UPDATE);
  }
}
