import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';
import BigNumber from 'bignumber.js';
import './i18n';
import './mock-telegram';
import AppRouter from './AppRouter';

BigNumber.config({ EXPONENTIAL_AT: 100, DECIMAL_PLACES: 36 });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <AppRouter>
    <App />

    <Toaster
      toastOptions={{
        style: {
          border: 'none',
        },
        success: {
          style: {
            background: '#1C1C1C',
            color: '#32D74B',
          },
        },
        error: {
          style: {
            background: '#1C1C1C',
            color: '#FF453A',
          },
        },
      }}
    />
  </AppRouter>,
);
