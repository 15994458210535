import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import SolanaFlipOrderItem from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipOrderItem';
import LimitOrderDetails from '@pages/LimitOrders/components/LimitOrderDetails';
import { OrderResponse } from '../../../../types/order/order.response';
import Sorter from '@pages/LimitOrders/SolanaFlip/components/Sorter';
import { observer } from 'mobx-react-lite';
import Placeholder from '@components/common/Placeholder';
import BigNumber from 'bignumber.js';
import { handleScrollToPixels, scrollToPixels } from '@helpers/scroll';
import { buyOrderTypes } from '../../../../constants';

enum OrdersSort {
  DATE = 'date',
  PRICE = 'price',
}

enum SortType {
  DEFAULT = 0,
  DESC = 1,
  ASC = 2,
}

const SolanaFlipOrders = observer(() => {
  const { ordersStore, flipStore, settingsStore } = useStores();
  const {
    orders,
    tokenDetails,
    limitData,
    nativeTokenPriceUsd,
    isOrdersLoaded,
    isSearching,
    isMcap,
  } = flipStore;
  const { isScrollMountedToOrders } = settingsStore;
  const { t } = useTranslation();
  const [sort, setSort] = useState<OrdersSort | null>(null);
  const [asc, setAsc] = useState<SortType>(SortType.DEFAULT);
  const [orderDetails, setOrderDetails] = useState<OrderResponse | null>(null);
  const ordersRef = useRef<HTMLDivElement>(null);

  const sortedOrders = useMemo(() => {
    return (orders || []).slice().sort((a, b) => {
      if (sort === OrdersSort.DATE) {
        const _a = new Date(a.updatedAt).getTime();
        const _b = new Date(b.updatedAt).getTime();
        return asc === SortType.ASC ? _a - _b : _b - _a;
      } else if (sort === OrdersSort.PRICE) {
        const isBuyA = buyOrderTypes.includes(a.type);
        const amountInA = new BigNumber(isBuyA ? a.valueOut : a.valueIn);
        const _a = new BigNumber(a.usdValue).div(amountInA).toNumber();
        const isBuyB = buyOrderTypes.includes(b.type);
        const amountInB = new BigNumber(isBuyB ? b.valueOut : b.valueIn);
        const _b = new BigNumber(b.usdValue).div(amountInB).toNumber();
        return asc === SortType.ASC ? _a - _b : _b - _a;
      }
      return 0;
    });
  }, [orders, sort, asc]);

  const offset = useMemo(() => {
    if (ordersRef.current) {
      const topBar = document.querySelector('.solana-flip-topbar')!;
      const tabsNav = document.querySelector('.solana-flip-tabs-nav')!;
      const settings = document.querySelector('.solana-flip-market-quick-settings')!;

      const topBarStyle = window.getComputedStyle(topBar);
      const tabsNavStyle = window.getComputedStyle(tabsNav);
      const settingsStyle = window.getComputedStyle(settings);

      const topBarOffset = parseInt(topBarStyle.height)
        + parseInt(topBarStyle.marginTop)
        + parseInt(topBarStyle.marginBottom);

      const tabsNavOffset = parseInt(tabsNavStyle.height)
        + parseInt(tabsNavStyle.marginTop)
        + parseInt(tabsNavStyle.marginBottom);

      const settingsOffset = parseInt(settingsStyle.height)
        + parseInt(settingsStyle.marginTop)
        + parseInt(settingsStyle.marginBottom);

      return topBarOffset + tabsNavOffset + settingsOffset;
    }

    return 0;
  }, [ordersRef.current]);

  useEffect(() => {
    if (ordersRef.current) {
      if (isScrollMountedToOrders) {
        scrollToPixels(offset);
      }

      const clearEventListener = handleScrollToPixels({
        offset,
        callback: () => {
          settingsStore.setScrollMountedToOrders(!isScrollMountedToOrders);
          !isScrollMountedToOrders && queueMicrotask(() => scrollToPixels(offset));
        },
        direction: isScrollMountedToOrders ? 'up' : 'down',
        once: true,
        delay: 300,
      });

      return clearEventListener;
    }
  }, [isScrollMountedToOrders, ordersRef.current, isSearching, isOrdersLoaded, orders, offset]);

  const updateOrders = useCallback(() => {
    if (!ordersStore.summary[0] || !ordersStore.isInit) return;

    flipStore.getOrders(true);
    flipStore.getBalance();
  }, [ordersStore.summary, ordersStore.isInit]);

  const dateSortIcon = sort === OrdersSort.DATE ? asc : SortType.DEFAULT;
  const priceSortIcon = sort === OrdersSort.PRICE ? asc : SortType.DEFAULT;

  const handleSort = (isDate: boolean) => {
    if (
      (isDate && sort === OrdersSort.DATE) ||
      (!isDate && sort === OrdersSort.PRICE)
    ) {
      setAsc((p) => (p + 1 > 2 ? SortType.DEFAULT : p + 1));
    } else {
      setSort(isDate ? OrdersSort.DATE : OrdersSort.PRICE);
      setAsc(SortType.DESC);
    }
  };
  const minHeight = useMemo(() => {
    const flipButtons = document.querySelector('.fixed-bottom')!;
    if (flipButtons) {
      const { height, paddingBottom, paddingTop } = window.getComputedStyle(flipButtons);
      const flipButtonsSize = parseInt(height) - parseInt(paddingBottom) - parseInt(paddingTop);
      const occupiedSize = flipButtonsSize + offset;

      return `calc(100dvh - ${occupiedSize - 24}px)`;
    }
    return 'auto';
  }, [offset, ordersRef.current]);

  if (isSearching || !isOrdersLoaded || !orders) {
    return (
      <div className="px-3" style={{ minHeight }} ref={ordersRef}>
        <Placeholder
          width={100}
          height={44}
          className="wd-100p ht-45 mb-1 d-block"
          fullWidth
        />
        <Placeholder
          width={100}
          height={44}
          className="wd-100p ht-45 mb-1 d-block"
          fullWidth
        />
        <Placeholder
          width={100}
          height={44}
          className="wd-100p ht-45 mb-1 d-block"
          fullWidth
        />
        <Placeholder
          width={100}
          height={44}
          className="wd-100p ht-45 mb-1 d-block"
          fullWidth
        />
      </div>
    );
  }

  return (
    <div className="px-3" style={{ minHeight: `calc(100dvh - ${offset}px)` }} ref={ordersRef}>
      <table className="table table-orders">
        <thead>
          <tr>
            <th onClick={() => handleSort(true)}>
              <span>{t('common.when')}</span>
              <Sorter asc={dateSortIcon} />
            </th>
            <th>SOL</th>
            <th>{t('common.amount')}</th>
            <th onClick={() => handleSort(false)}>
              <span>{t(isMcap ? 'common.mcap-shrt' : 'common.price')}</span>
              <Sorter asc={priceSortIcon} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {isOrdersLoaded && !flipStore.orders?.length && (
            <tr>
              <td colSpan={4} className="tx-center tx-muted tx-12 pt-2">
                {t('order.no-active-orders')}
              </td>
            </tr>
          )}
          {isOrdersLoaded &&
            sortedOrders.map((order, index) => (
              <SolanaFlipOrderItem
                item={order}
                isLast={index === sortedOrders.length - 1}
                key={`solana-flip-order-${tokenDetails?.pairAddress}-${order.id}`}
                onClick={() => setOrderDetails(order)}
                limitData={limitData}
                nativePrice={nativeTokenPriceUsd}
                isMcap={isMcap}
              />
            ))}
        </tbody>
      </table>

      <LimitOrderDetails
        item={orderDetails}
        limitData={limitData}
        nativePrice={nativeTokenPriceUsd}
        onClose={() => setOrderDetails(null)}
        onCancel={updateOrders}
      />
    </div>
  );
});

export default SolanaFlipOrders;
