import React, { useMemo } from 'react';
import image from '@assets/images/flipping-volume.png';
import FormattedNumber from '@components/common/FormattedNumber';
import Progress from '@components/common/Progress';
import lock from '@assets/icons/lock-outline.svg';
import check from '@assets/icons/checkmark-green.svg';
import Vibrutton from '@components/common/Vibrutton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../../constants';

interface FlipModeVolumeAlertProps {
  volume: number;
}

const thresholds = [25, 50, 100, 150, 200, 300];
const opened = [25, 50, 100, 150, 200, 300];

const FlipModeVolumeAlert = ({volume}: FlipModeVolumeAlertProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const volumes = useMemo(() => {
    return thresholds.map((t, i) => {
      const prev = i > 0 ? thresholds[i - 1] : 0;
      const required = t - prev;
      const value = volume - prev * 1000;
      const isReached = volume > t * 1000;
      const isReachedPrev = volume > prev * 1000;
      return {
        label: `${t}K`,
        isOpened: opened.includes(t),
        value: isReached ? 100 : isReachedPrev ? value : 0,
        max: isReached ? 100 : required * 1000,
      };
    });
  }, [volume]);

  return (
    <div className="px-3 pt-2">
      <div className="tx-34 tx-semibold lh-2 mb-3">{t('trading.flip-volume-alert-title')}</div>
      <div className="tx-16 lh-2">{t('trading.flip-volume-alert-description')}</div>
      <img
        src={image}
        alt="flipping-volume"
        className="img-fluid my-3 wd-100p"
      />
      <div className="tx-12 tx-muted lh-1">{t('referral.personal-turnover')}:</div>
      <FormattedNumber
        value={volume}
        suffix="$"
        decimals={0}
        floor
        className="tx-28 tx-semibold mb-3"
      />

      <div className="d-flex flex-row gap-2px mt-2">
        {volumes.map(({value, label, max, isOpened}) => (
          <div key={`flip-mode-volume-bar-${label}`} className="wd-100p d-flex flex-column align-items-center">
            <div className="wd-100p mb-2">
              <Progress
                value={value}
                max={max}
                bgColor={isOpened ? 'semi-success' : 'semi-transparent'}
                forceColor={isOpened ? 'semi-success' : 'semi-transparent'}
                label={label}
                className={isOpened ? 'tx-success' : 'tx-muted'}
              />
            </div>
            <img src={isOpened ? check : lock} alt={isOpened ? 'checkmark' : 'lock'} width={12} />
          </div>
        ))}
      </div>

      <Vibrutton
        className="btn btn-primary wd-100p mt-4 py-2"
        onClick={() => navigate(PageRoutes.RESEARCH)}
      >
        {t('common.trade')}
      </Vibrutton>
    </div>
  );
};

export default FlipModeVolumeAlert;
