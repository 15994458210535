import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import cn from 'classnames';
import copy from '@assets/icons/copy-gray.svg';
import chevrons from '@assets/icons/chevrons.svg';
import chevronDownDoubleGray from '@assets/icons/chevron-down-double-gray.svg';
import flipPro from './assets/flip-pro.svg';
import FormattedNumber from '@components/common/FormattedNumber';
import CopyButton from '@components/common/CopyButton';
import { getEllipsisTxt } from '@helpers/formatters';
import Vibrutton from '@components/common/Vibrutton';
import Tabs, { TabItem, TabToolItem } from '@components/Tabs';
import SolanaFlipMarket from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipMarket';
import SolanaFlipSettings from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipSettings';
import { useTranslation } from 'react-i18next';
import SolanaFlipWrapperModal from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipWrapperModal';
import SolanaFlipTopBar from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipTopBar';
import Placeholder from '@components/common/Placeholder';
import { useParams } from 'react-router-dom';
import { FlipRouteParams } from '@stores/flip-store';
import { greaterThanOrEqual } from '@helpers/bignumber';
import BigNumber from 'bignumber.js';
import FlipModeVolumeAlert from '@pages/LimitOrders/SolanaFlip/FlipModeVolumeAlert';
import Preloader from '@components/common/Preloader';
import { OrderResponse } from '../../../types/order/order.response';

const SolanaFlip = observer(() => {
  const { t } = useTranslation();
  const { flipStore, accountStore, settingsStore } = useStores();
  const {
    tokenDetails,
    buySell,
    tokenBalance,
    isSearching,
    isMcap,
    isPro,
    isLyubaMode,
    orders,
  } = flipStore;
  const { refData } = accountStore;
  const { flipAvailable } = settingsStore;
  const [isValue, setIsValue] = useState(true);
  const params = useParams<FlipRouteParams>();
  const [lastPrice, setLastPrice] = useState<string | null>(null);
  const [priceDown, setPriceDown] = useState<boolean>(false);
  const [selectedTab, selectTab] = useState(0);

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        label: 'common.market',
        component: <SolanaFlipMarket />,
      },
      {
        label: 'common.settings',
        component: <SolanaFlipSettings />,
      },
    ],
    [],
  );

  const tabsTools: TabToolItem[] = useMemo(() => {
    if (selectedTab !== 0) {
      return [
        {
          label: t('common.reset'),
          component: null,
          handler: () => flipStore.resetFlipSettings(),
        },
      ];
    }

    return [
      {
        label: '',
        component: null,
        icon: chevronDownDoubleGray,
        handler: () => settingsStore.setScrollMountedToOrders(true),
      },
    ];
  }, [settingsStore.setScrollMountedToOrders, selectedTab]);

  const headerData = useMemo(() => {
    return {
      title: isMcap ? 'trading.mcap' : 'trading.token-price',
      value: isMcap ? tokenDetails?.mcap : tokenDetails?.usdPrice,
      isPositive:
        lastPrice && tokenDetails
          ? priceDown
          : (tokenDetails?.priceChangePercent24h || 0) >= 0,
    };
  }, [isMcap, tokenDetails]);

  const calculatedUnrealizedProfit = useMemo(() => {
    if (!tokenBalance || !tokenDetails) return null;

    return new BigNumber(tokenBalance.tokenBalance)
      .multipliedBy(
        BigNumber(tokenDetails.priceInBaseToken).minus(
          tokenBalance.avgBuyPriceInNative || 0,
        ),
      )
      .toNumber();
  }, [tokenBalance, tokenDetails]);

  const unrealized = useMemo(
    () =>
      calculatedUnrealizedProfit || tokenBalance?.unrealizedProfitInNative || 0,
    [tokenBalance, tokenDetails],
  );

  const getPercent = useCallback(() => {
    if (!tokenBalance) return null;
    if (new BigNumber(tokenBalance!.tokenBalanceInNative ?? 0).isZero())
      return 0;
    else
      return new BigNumber(unrealized ?? 0)
        .div(tokenBalance!.tokenBalanceInNative ?? 1)
        .times(100);
  }, [tokenBalance, tokenDetails]);

  const valueProfitData = useMemo(() => {
    const percent = getPercent();

    return {
      title: isValue ? 'common.value' : 'trading.unrealized-profit',
      value: isValue
        ? tokenBalance
          ? tokenBalance?.tokenBalanceInNative || 0
          : null
        : unrealized,
      percent: percent && percent.valueOf(),
      isPositive: percent && percent.isGreaterThanOrEqualTo(0).valueOf(),
    };
  }, [isValue, tokenBalance, calculatedUnrealizedProfit, tokenBalance]);

  const tradeEventHandler =
    (success: boolean) => async (data: OrderResponse) => {
      console.timeEnd('tradeBuy');

      // flipStore.clearHistoryPendingItems();
      flipStore.setLatestOrder(data);
    };

  useEffect(() => {
    enum Events {
      SUCCESS_TRADE_NOTIFICATION = 'SUCCESS_TRADE_NOTIFICATION',
      FAILED_TRADE_NOTIFICATION = 'FAILED_TRADE_NOTIFICATION',
      NEW_TRADE = 'NEW_TRADE',
    }

    flipStore.socket?.on(
      Events.SUCCESS_TRADE_NOTIFICATION,
      tradeEventHandler(true),
    );
    flipStore.socket?.on(
      Events.FAILED_TRADE_NOTIFICATION,
      tradeEventHandler(false),
    );

    return () => {
      flipStore.socket?.off(Events.SUCCESS_TRADE_NOTIFICATION);
      flipStore.socket?.off(Events.FAILED_TRADE_NOTIFICATION);
    };
  }, [orders, flipStore.socket]);

  useEffect(() => {
    if (
      params &&
      params.address &&
      (!tokenDetails || tokenDetails.pairAddress !== params.address) &&
      !isSearching
    ) {
      flipStore.setRouteParams(params);
    }
  }, [params, tokenDetails, isSearching]);

  useEffect(() => {
    return () => {
      flipStore.reset();
    };
  }, []);

  useEffect(() => {
    if (tokenDetails) {
      if (lastPrice && lastPrice !== tokenDetails.usdPrice) {
        setPriceDown(greaterThanOrEqual(lastPrice, tokenDetails.usdPrice));
      }
      setLastPrice(tokenDetails.usdPrice);
    }
  }, [tokenDetails, lastPrice]);

  if (!refData) {
    return <Preloader className="py-3" inline />;
  }

  if (
    !flipAvailable &&
    refData &&
    new BigNumber(refData.personalAllTimeUsdVolume).isLessThan(25000)
  ) {
    return (
      <FlipModeVolumeAlert
        volume={new BigNumber(refData.personalAllTimeUsdVolume).toNumber()}
      />
    );
  }

  if (!tokenDetails && !isSearching) {
    return (
      <div className="full-page overflow-scroll row py-0">
        <div className="px-0 pt-3">
          <SolanaFlipTopBar />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`full-page overflow-scroll row py-0 ${isPro ? 'flip-pro' : ''}`}
    >
      <div className="px-0 pt-3">
        <SolanaFlipTopBar />
        <div
          className={`${isPro ? 'bg-dark' : 'bg-secondary'} py-3 px-3 border-top border-bottom border-semi-transparent position-sticky top-0 z-index-10`}
        >
          <div className="d-flex align-items-center tx-12">
            {isPro && <img src={flipPro} alt="flip-pro" className="me-2" />}
            <SolanaFlipWrapperModal />
            {tokenDetails ? (
              <CopyButton
                text={tokenDetails.quoteToken.address}
                className="d-flex align-items-center ms-auto me-0 bg-semi-transparent-10 border border-semi-transparent px-1 py-0 rounded-4px tx-muted cur-pointer"
                noIcon
              >
                <span className="me-1">
                  {getEllipsisTxt(tokenDetails.quoteToken.address, 4)}
                </span>
                <img src={copy} alt="copy" width={12} />
              </CopyButton>
            ) : (
              <Placeholder width={104} height={20} className="ms-auto me-0" />
            )}
          </div>

          <Vibrutton
            className="btn btn-link text-decoration-none wd-100p p-0 d-flex flex-column align-items-center mt-3 cur-pointer"
            onClick={() => flipStore.setIsMcap(!isMcap)}
          >
            <div className="tx-10 tx-muted">{t(headerData.title)}</div>
            {tokenDetails ? (
              <div
                className={`d-flex align-items-center tx-28 tx-semibold tx-white`}
              >
                <FormattedNumber
                  value={headerData.value}
                  suffix="$"
                  className="mx-1 lh-1"
                  decimals={4}
                  subZeros
                />
                <img src={chevrons} alt="chevrons" width={24} />
              </div>
            ) : (
              <Placeholder width={169} height={32} className="mx-auto" />
            )}
          </Vibrutton>

          <div className="row mx-0 tx-center mt-3">
            <div className="col-4">
              <div className="tx-10 tx-muted">{t('trading.buy-sell')}</div>
              {tokenDetails ? (
                <div className="d-flex align-items-center justify-content-center tx-12 mt-2">
                  <FormattedNumber
                    value={buySell.buy}
                    decimals={buySell.buy && buySell.buy > 100 ? 0 : 3}
                    subZeros
                  />
                  <span className="mx-1">/</span>
                  <FormattedNumber
                    value={buySell.sell}
                    decimals={buySell.sell && buySell.sell > 100 ? 0 : 3}
                    subZeros
                  />
                  <span className="ms-1">SOL</span>
                </div>
              ) : (
                <Placeholder width={77} height={20} className="ms-auto me-0" />
              )}
            </div>

            <div className="col-4">
              <div className="tx-10 tx-muted">{t('common.total-profit')}</div>
              {tokenDetails ? (
                <div className="d-flex align-items-center justify-content-center mt-1">
                  <FormattedNumber
                    value={tokenBalance?.pureNativeProfit}
                    decimals={2}
                    subZeros
                    withSign={(tokenBalance?.pureNativeProfit || 0) < 0}
                  />
                  <span className="ms-1">SOL</span>
                </div>
              ) : (
                <Placeholder width={77} height={20} className="ms-auto me-0" />
              )}
            </div>

            <div
              className={`col-4 rounded-2 border-1 border-solid ${isLyubaMode ? (valueProfitData.isPositive ? 'bg-semi-success-10 border-semi-success' : 'bg-semi-danger-10 border-semi-danger') : isPro ? 'border-dark' : 'border-secondary'}`}
            >
              <div className="tx-10 tx-muted">
                {t(valueProfitData.title)}
                {isLyubaMode && (
                  <FormattedNumber
                    value={valueProfitData.percent}
                    className={cn([
                      'ms-1',
                      valueProfitData.isPositive === true && 'tx-success',
                      valueProfitData.isPositive === false && 'tx-danger',
                    ])}
                    decimals={2}
                    postfix="%"
                    floor
                    withSign
                  />
                )}
              </div>
              <Vibrutton
                className={cn([
                  'btn btn-link text-decoration-none p-0 tx-center wd-100p',
                ])}
                onClick={() => setIsValue((v) => !v)}
              >
                {tokenDetails ? (
                  <div
                    className={cn([
                      'd-flex align-items-center justify-content-center cur-pointer tx-12',
                      isLyubaMode || valueProfitData.isPositive === null
                        ? 'tx-white'
                        : valueProfitData.isPositive
                          ? 'tx-success'
                          : 'tx-danger',
                    ])}
                  >
                    <FormattedNumber
                      value={valueProfitData.value}
                      decimals={
                        !!valueProfitData.value && valueProfitData.value > 100
                          ? 0
                          : 4
                      }
                      // decimals={!!valueProfitData.value && valueProfitData.value > 100 ? 0 : !!valueProfitData.value && valueProfitData.value > 1 ? 2 : 3}
                      subZeros={
                        !!valueProfitData.value && valueProfitData.value < 1
                      }
                      withSign={(valueProfitData.value || 0) < 0}
                    />
                    <span className="mx-1">SOL</span>
                    <img src={chevrons} alt="chevrons" width={14} />
                  </div>
                ) : (
                  <Placeholder
                    width={77}
                    height={20}
                    className="ms-auto me-0"
                  />
                )}
              </Vibrutton>
            </div>
          </div>
        </div>
        <div>
          <Tabs
            items={tabItems}
            tools={tabsTools}
            onChange={selectTab}
            navClassName="solana-flip-tabs-nav"
          />
        </div>
      </div>
    </div>
  );
});

export default SolanaFlip;
