import {
  action,
  makeAutoObservable,
  observable,
  computed,
} from 'mobx';
import { TelegramWebApp } from 'types/telegram-web-app';
import {
  init,
  isTMA,
  backButton,
  expandViewport,
} from '@telegram-apps/sdk-react';
import { isLocalhost } from '@helpers/device';

export class TelegramStore {
  @observable
  private _isTMA: boolean = false;

  @observable
  private _isSDKLoaded: boolean = false;

  @observable
  private _iTelegram: TelegramWebApp;

  @observable
  private _queryToken: string | null;

  constructor() {
    makeAutoObservable(this);

    const onTgLoad = async () => {
      const isTgWebApp = isTMA('simple');
      console.log('TelegramStore: isTMA check result:', isTgWebApp);
      console.log('TelegramStore: window.Telegram?.WebApp available:', !!window.Telegram?.WebApp);
      
      this._isTMA = isTgWebApp;

      const query = new URLSearchParams(window.location.search);
      this._queryToken = query.get('token');

      if (isTgWebApp) {
        this.setTelegram({ WebApp: window.Telegram?.WebApp });
        this.setSDKLoaded();

        if (!isLocalhost()) {
          console.log('TelegramStore: Initializing Telegram SDK (non-localhost)');
          init({
            acceptCustomStyles: true,
          });

          expandViewport();
          // Mount the Back Button, so we will work with
          // the actual component properties.
          backButton.mount();
        } else {
          console.log('TelegramStore: Running on localhost, skipping SDK initialization');
        }
      } else {
        console.log('TelegramStore: Not running as a Telegram mini app');
      }
    };

    onTgLoad();
  }

  @computed
  get queryToken() {
    return this._queryToken;
  }

  @computed
  get isTMA() {
    return this._isTMA;
  }

  @computed
  get webApp() {
    return this._iTelegram?.WebApp;
  }

  @computed
  get isSDKLoaded() {
    return this._isSDKLoaded;
  }

  @action.bound
  setTelegram(app: TelegramWebApp) {
    this._iTelegram = app;
  }

  @action.bound
  setIsTMA(v: boolean = true) {
    this._isSDKLoaded = v;
  }

  @action.bound
  setSDKLoaded(v: boolean = true) {
    this._isSDKLoaded = v;
  }

  @action.bound
  resetQueryToken() {
    this._queryToken = null;
  }
}
