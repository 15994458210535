import React, { useEffect } from 'react';
import { ENV } from '../../../constants';
import Vibrutton from '@components/common/Vibrutton';
import googleIcon from '@assets/images/google.svg';


const LoginWithGoogle = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client"; // Replace with your script URL
    script.async = true;
    script.onload = () => {
      console.log("Script loaded successfully!");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup on unmount
    };
  }, []);

  return (
    <div className="wd-50 mn-wd-50 mx-wd-50 ht-50 pos-relative overflow-hidden flex-1 z-index-50" id="google-login-button">
      <div id="g_id_onload"
           data-client_id={ENV.REACT_APP_GOOGLE_CLIENT_ID}
           data-login_uri={`${ENV.API_URL}/api/v1/auth/login-google`}
           data-auto_prompt="false">
      </div>
      <Vibrutton
        className="btn btn-outline-secondary rounded-circle d-flex align-items-center justify-content-center wd-50 mn-wd-50 mx-wd-50 p-0 pos-absolute absolute-item"
      >
        <img src={googleIcon} alt="google" />
      </Vibrutton>
      <div className="g_id_signin"
           data-type="icon"
           data-size="large"
           data-theme="outline"
           data-text="sign_in_with"
           data-shape="circle"
           data-logo_alignment="left">
      </div>
    </div>
  );
};

export default LoginWithGoogle;
