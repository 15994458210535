import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NumericInput from '@components/common/NumericInput';
import SettingsInput from '@components/common/SettingsInput';
import TabsCard from '@components/common/TabsCard';
import SnipeRow from '@pages/Profile/SnipeRow';
import { useTranslation } from 'react-i18next';
import { SolPriorityMode } from 'types/user/settings.types';
import { IOrderSettings } from 'types';
import { defaultOrderSettings } from '@stores/settings-store-old';
import clone from '@helpers/clone';
import cn from 'classnames';
import { smallerThan } from '@helpers/bignumber';
import { ChainId, numberRegex } from '../../constants';
import { ApiError } from '@helpers/api';
import { Blockchain } from 'types/enums';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import solana from '@assets/images/solana.svg';
import Toggle from 'react-toggle';
import BigNumber from 'bignumber.js';
import isEqual from 'lodash.isequal';

type Props = {
  data: IOrderSettings;
  chain?: ChainId;
  onChange: (settings: IOrderSettings) => void;
  onError: (
    errors: {
      check: boolean;
      label: string;
    }[],
  ) => void;
};

const OrderSettings = ({
  data,
  chain: forceChain,
  onChange,
  onError,
}: Props) => {
  const { t } = useTranslation();
  const { tokenTrade, quickTradeStore } = useStores();

  const { chain: tradeChain } = tokenTrade;
  const { chain: quickChain } = quickTradeStore;

  const chain = useMemo(
    () => forceChain ?? quickChain ?? tradeChain,
    [forceChain, tradeChain, quickChain],
  );
  const [settings, setSettings] = useState<IOrderSettings>(
    clone(data ?? defaultOrderSettings(chain as Blockchain)),
  );
  const [error, setError] = useState<ApiError | null>(null);
  const [isSolChecked, setIsSolChecked] = useState<boolean>(false);

  useEffect(() => {
    setSettings(clone(data ?? defaultOrderSettings(chain as Blockchain)));
  }, [data]);

  useEffect(() => {
    if (!isSolChecked && chain === Blockchain.SOLANA) {
      setSettings((prev) => {
        if (!data) {
          return {
            ...prev,
            antiMevEnabled: false,
          };
        }
        return prev;
      });
      setIsSolChecked(true);
    }
  }, [chain, data, isSolChecked]);

  const isInputHidden = (key: string) => {
    const disabledChains = {
      extraGasPricePercent: [
        Blockchain.TON,
        Blockchain.TRON,
        Blockchain.SOLANA,
        Blockchain.SUI,
      ],
      antiMevEnabled: [
        Blockchain.BASE,
        Blockchain.TON,
        Blockchain.TRON,
        Blockchain.SUI,
      ],
      maxGasPrice: [
        Blockchain.BSC,
        Blockchain.BASE,
        Blockchain.SOLANA,
        Blockchain.TON,
        Blockchain.TRON,
        Blockchain.SUI,
      ],
      autoApproveEnabled: [Blockchain.SOLANA, Blockchain.TON, Blockchain.SUI],
    }[key];

    return (
      disabledChains?.includes(forceChain as unknown as Blockchain) ||
      disabledChains?.includes(tradeChain as Blockchain) ||
      disabledChains?.includes(quickChain as Blockchain)
    );
  };

  const isSolana = useMemo(() => {
    return chain === Blockchain.SOLANA;
  }, [chain]);

  const isInvalid = useCallback(
    (key: string, isReq = false) => {
      if (!settings) {
        return false;
      }
      // @ts-ignore
      const value = settings[key];
      if (isReq && (value === undefined || value === null || value === '')) {
        return true;
      }
      if (!isReq && (value === undefined || value === null || value === '')) {
        return false;
      }
      if (value && smallerThan(value, 0)) {
        return true;
      }
      if (key === 'slippage' && value && (value < 0 || value > 100)) {
        return true;
      }
      return (
        isNaN(value) ||
        !numberRegex.test(value.toString()) ||
        !!(error && error.error?.includes(key))
      );
    },
    [settings, error],
  );

  const solFeeInvalid = useMemo(() => {
    if (settings.solPriorityMode !== SolPriorityMode.CUSTOM) {
      return false;
    }
    return new BigNumber(settings.solPriorityFee || 0).isLessThan(0.0001);
  }, [settings.solPriorityFee, settings.solPriorityMode]);

  const setSolMode = (mode: SolPriorityMode) => {
    setSettings({
      ...settings,
      solPriorityMode: mode,
    });
  };

  const setSolFee = (fee: number | null) => {
    setSettings({
      ...settings,
      solPriorityFee: fee,
    });
  };

  const checkValidation = useMemo(() => {
    const checks = [
      {
        check: isInvalid('slippage'),
        label: t('trading.slippage'),
      },
      {
        check: isSolana ? solFeeInvalid : isInvalid('extraGasPrice'),
        label: isSolana
          ? t('profile.priority-fee')
          : t('order.extra-gas-price'),
      },
    ];

    return checks.filter((c) => c.check);
  }, [settings]);

  useEffect(() => {
    onError(checkValidation);
  }, [checkValidation]);

  useEffect(() => {
    if (!isEqual(data, settings)) {
      onChange(settings);
    }
  }, [settings]);

  const handleOnChange = useCallback(
    (key: string, value: string | number | null) => {
      if (error && error.error?.includes(key)) {
        setError(null);
      }
    },
    [error],
  );

  return (
    <div className="d-flex flex-column gap-12px mb-1">
      <SnipeRow>
        <SettingsInput
          className="tx-muted"
          data={settings}
          setHandler={setSettings}
          dataKey="slippage"
          isInvalidHandler={isInvalid}
          placeholder="5%"
          unit="%"
          onChange={handleOnChange}
          // tooltip={t('order.percentage-profit-sell-executed')}
        >
          {t('trading.slippage')}
        </SettingsInput>
      </SnipeRow>

      <SnipeRow>
        <SettingsInput
          data={settings}
          className={cn([
            'tx-muted',
            isInputHidden('extraGasPricePercent') && 'd-none',
          ])}
          setHandler={setSettings}
          dataKey="extraGasPricePercent"
          isInvalidHandler={isInvalid}
          placeholder="10%"
          unit="%"
          onChange={handleOnChange}
          // tooltip={t('order.extra-gas-price-description')}
        >
          {t('order.extra-gas-price')}
        </SettingsInput>

        <SettingsInput
          data={settings}
          className={cn(['tx-muted', isInputHidden('maxGasPrice') && 'd-none'])}
          setHandler={setSettings}
          dataKey="maxGasPrice"
          isInvalidHandler={isInvalid}
          placeholder="200 GWEI"
          unit="GWEI"
          onChange={handleOnChange}
          // tooltip={t('order.extra-gas-price-description')}
        >
          {t('trading.max-gas-price')}
        </SettingsInput>
      </SnipeRow>

      <div className={chain === Blockchain.SOLANA ? 'd-block' : 'd-none'}>
        <div className="tx-13 tx-muted mb-2">{t('profile.priority-fee')}</div>

        <TabsCard
          className="wd-100p"
          list={Object.values(SolPriorityMode).map((value: string) => ({
            value,
            label: t(
              `common.${value.toLowerCase()}${value === SolPriorityMode.CUSTOM ? '-short' : ''}`,
            ),
          }))}
          active={settings.solPriorityMode || SolPriorityMode.FAST}
          onClick={setSolMode}
        />

        <div
          className={cn([
            'card',
            'bd-gray-800 rounded-10-px bd-solid bd-1 p-2',
            'flex-row',
            'align-items-center',
            settings.solPriorityMode === SolPriorityMode.CUSTOM
              ? 'd-flex'
              : 'd-none',
          ])}
        >
          <div className="tx-muted me-2">SOL</div>
          <NumericInput
            placeholder="0.001"
            className="form-control appearance-none tx-left"
            value={settings.solPriorityFee?.toString() || null}
            onChange={(v) => setSolFee(v ? new BigNumber(v).toNumber() : null)}
            isInvalid={solFeeInvalid}
          />
        </div>
      </div>

      <SnipeRow className="gap-2">
        <SnipeRow
          className={cn([
            isInputHidden('antiMevEnabled') && 'd-none',
            'flex-nowrap flex-grow-1 justify-content-between align-items-center pd-12 bd-gray-800 rounded-10-px bd-solid bd-1',
          ])}
        >
          <div className="d-flex flex-column wd-100p gap-12px">
            <div className="d-flex">
              <div className="me-auto">
                <div className="tx-semibold">{t('trading.mev-guard')}</div>
                <div className="tx-13 tx-muted">
                  {t('trading.mev-guard-description')}
                </div>
              </div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.antiMevEnabled}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    antiMevEnabled: e.target.checked,
                  })
                }
              />
            </div>
            {settings.antiMevEnabled && isSolana && (
              <NumericInput
                postfix=" SOL"
                placeholder="0.001"
                className="form-control appearance-none tx-left"
                value={settings.solTip?.toString() || null}
                onChange={(v) =>
                  setSettings({
                    ...settings,
                    solTip: v ? new BigNumber(v).toNumber() : null,
                  })
                }
              />
            )}
          </div>
        </SnipeRow>
        <SnipeRow
          className={cn([
            isInputHidden('autoApproveEnabled') && 'd-none',
            'flex-nowrap flex-grow-1 justify-content-between align-items-center pd-12 bd-gray-800 rounded-10-px bd-solid bd-1',
          ])}
        >
          <div>
            <div className="tx-semibold">{t('trading.auto-approve')}</div>
            <div className="tx-13 tx-muted">
              {t('trading.auto-approve-description')}
            </div>
          </div>
          <Toggle
            icons={false}
            className="styled-toggle my-2"
            checked={settings.autoApproveEnabled}
            onChange={(e) =>
              setSettings({
                ...settings,
                autoApproveEnabled: e.target.checked,
              })
            }
          />
        </SnipeRow>
      </SnipeRow>
    </div>
  );
};

export default observer(OrderSettings);
