import React from 'react';
import { LoginButton } from '@telegram-auth/react';
import { ENV } from '../../../constants';
import Vibrutton from '@components/common/Vibrutton';
import plane from '@assets/icons/plane-telegram.svg';
import { useTranslation } from 'react-i18next';


const LoginWithTelegram = () => {
  const { t } = useTranslation();

  return (
    <div className="wd-100p pos-relative overflow-hidden flex-1 z-index-10" id="telegram-login-button">
      <Vibrutton
        className="btn btn-blue text-center wd-100p"
      >
        <img src={plane} alt="plane" className="me-2" />
        {t('onboarding.login-telegram')}
      </Vibrutton>
      <LoginButton
        requestAccess="write"
        botUsername={ENV.BOT_USERNAME}
        authCallbackUrl={`${ENV.API_URL}/api/v1/auth/login-telegram`}
        buttonSize="large" // "large" | "medium" | "small"
        cornerRadius={0} // 0 - 20
        showAvatar={false} // true | false
        lang="en"
      />
    </div>
  );
};

export default LoginWithTelegram;
