import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  computed,
} from 'mobx';
import api, { ApiMethod, apiRaw } from '@helpers/api';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { ChainId } from '../constants';
import {
  GetPortfolioTransactionsResponse,
  IFungible,
  PortfolioTransaction,
  ShareProfitResponse,
  ShareType,
  WalletBalance,
} from '../types';
import { GetPortfolioTokensResponse, Portfolio } from 'types/portfolio.model';
import { AccountStore } from '@stores/account-store';
import {
  allowedBlockchains,
  allowedChains,
  chainToWallet,
} from '@helpers/chains';
import { effect } from '@helpers/mobx';
import { Blockchain, WalletType } from '../types/enums';
import { CreateWalletRequest } from '../types/wallet/create-wallet.request';
import { WalletResponse } from '../types/wallet/wallet.response';
import { ImportWalletRequest } from '../types/wallet/import-wallet.request';
import {
  getLocalStorageObject,
  updateLocalStorageObject,
} from '@helpers/localStorage';

type RecentWallet = [string, number];
type RecentWallets = { [p: string]: RecentWallet[] };

const defaultRecent: RecentWallets = allowedChains.reduce(
  (acc, chain) => ({ ...acc, [chain]: [] }),
  {},
);

export class WalletStore {
  @observable
  private _assetsLoaded = false;

  @observable
  private _transactionsLoaded = false;

  @observable
  private _portfolio: Portfolio | null = null;

  @observable
  private _assets: GetPortfolioTokensResponse = [];

  @observable
  private _transactions: PortfolioTransaction[] = [];

  @observable
  private _balance: WalletBalance | null = null;

  @observable
  private _recentWallets: RecentWallets = defaultRecent;

  private readonly accountStore: AccountStore;

  constructor(_accountStore: AccountStore) {
    this.accountStore = _accountStore;
    const _recent = getLocalStorageObject('recentWallets');
    if (_recent) {
      this._recentWallets = _recent;
    }

    makeAutoObservable(this);

    reaction(
      () =>
        JSON.stringify([
          this.accountStore.currentWallet,
          this.accountStore.network,
        ]),
      (c, p) => {
        const [currentWallet, currentNetwork] = JSON.parse(c);
        const [prevWallet, prevNetwork] = JSON.parse(p ?? '[]');

        if (currentNetwork !== prevNetwork && prevWallet?.id) {
          updateLocalStorageObject('lastWallet', {
            [prevNetwork as string]: prevWallet?.id,
          });
        }

        if (this.accountStore.currentWallet) {
          this.loadData();
        }
      },
      { fireImmediately: true },
    );

    reaction(
      () => this.accountStore.wallets,
      () => {
        const wallets = this.accountStore.wallets;
        if (wallets.length) {
          if (!wallets.find((w) => w.type === WalletType.SOL)) {
            this.createWallet(WalletType.SOL).then((r) => {
              if (r && r.id) {
                this.accountStore.loadUser();
              }
            });
          }
          if (!wallets.find((w) => w.type === WalletType.TON)) {
            this.createWallet(WalletType.TON).then((r) => {
              if (r && r.id) {
                this.accountStore.loadUser();
              }
            });
          }
          if (!wallets.find((w) => w.type === WalletType.SOL)) {
            this.createWallet(WalletType.SOL).then((r) => {
              if (r && r.id) {
                this.accountStore.loadUser();
              }
            });
          }
          if (!wallets.find((w) => w.type === WalletType.TRON)) {
            this.createWallet(WalletType.TRON).then((r) => {
              if (r && r.id) {
                this.accountStore.loadUser();
              }
            });
          }
          if (!wallets.find((w) => w.type === WalletType.SUI)) {
            this.createWallet(WalletType.SUI).then((r) => {
              if (r && r.id) {
                this.accountStore.loadUser();
              }
            });
          }
        }
      },
    );
  }

  @computed
  private get blockchain() {
    return this.accountStore.network;
  }

  @action.bound
  async loadData() {
    if (
      this.accountStore.currentWalletType === chainToWallet(this.blockchain)
    ) {
      this.loadPortfolio();
      this.loadAssets();
      return this.loadTransactions();
    }
  }

  @action.bound
  async loadBalance(id?: string, network?: ChainId) {
    this._balance = null;

    return apiRaw<WalletBalance>({
      method: ApiMethod.GET,
      path: `/balance/native`,
      data: {
        walletId: id || this.accountStore.currentWallet?.id,
        blockchain:
          network ||
          (this.accountStore.network === ChainId.ALL
            ? ChainId.ETHER
            : this.accountStore.network),
      },
    })
      .then((response) => {
        if (!id) {
          this._balance = response.data || null;
        } else {
          return response.data;
        }
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          // Обработка кейса с запросом баланса неверного блокчейна
          if (response.response.status === 500) {
            this.accountStore.resetWalletData();
            Sentry.captureEvent(
              { message: 'Wallet ID Error' },
              { data: { response, source: 'account-store' } },
            );
          }
        } else {
          console.error(response);
        }
      });
  }

  @action.bound
  async loadPortfolio() {
    this._portfolio = null;

    const data = { blockchain: this.blockchain };

    try {
      const res = await api<Portfolio>({
        method: ApiMethod.GET,
        path: `/portfolio/${this.accountStore.address}`,
        data,
      });

      this._portfolio = res;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  async loadAssets() {
    this._assetsLoaded = false;

    const data = { blockchain: this.blockchain };
    try {
      const res = await api<GetPortfolioTokensResponse>({
        method: 'get',
        path: `/portfolio/${this.accountStore.address}/tokens`,
        data,
      });

      this._assets = res;
      this._assetsLoaded = true;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  async loadFungible(fungibleId: string) {
    const data = { walletType: this.accountStore.currentWallet?.type };
    return api<IFungible>({
      method: 'get',
      path: `/portfolio/${this.accountStore.address}/fungible/${fungibleId}`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action.bound
  async getTokenTransactions({
    tokenAddress,
    blockchain,
  }: { tokenAddress?: string; blockchain?: Blockchain } = {}) {
    const data: Record<string, typeof tokenAddress> = {
      blockchain: this.accountStore.network as unknown as Blockchain,
    };
    if (tokenAddress) {
      data['tokenAddress'] = tokenAddress;
    }
    if (blockchain) {
      data['blockchain'] = blockchain;
    }
    return api<GetPortfolioTransactionsResponse>({
      method: 'get',
      path: `/portfolio/${this.accountStore.address}/transactions`,
      data,
    })
      .then((response) => {
        return response?.transactions || [];
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action.bound
  async getTransactions({
    tokenAddress,
    blockchain,
  }: { tokenAddress?: string; blockchain?: Blockchain } = {}) {
    const data: Record<string, typeof tokenAddress> = {
      blockchain:
        blockchain || (this.accountStore.network as unknown as Blockchain),
    };
    if (tokenAddress) {
      data['tokenAddress'] = tokenAddress;
    }
    return api<GetPortfolioTransactionsResponse>({
      method: 'get',
      path: `/portfolio/${this.accountStore.address}/transactions`,
      data,
    })
      .then((response) => {
        return response?.transactions || [];
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action.bound
  async getShareProfitImage(
    pairId: string,
    walletId: string,
    blockchain: ChainId,
    shareType = ShareType.DEFAULT,
  ) {
    return api<ShareProfitResponse>({
      method: 'get',
      path: `/share-profit`,
      data: {
        pairId,
        walletId,
        blockchain,
        shareType,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action.bound
  async loadTransactions() {
    this._transactionsLoaded = false;
    Promise.all([this.getTransactions()])
      .then((response) => {
        this._transactions = response[0];
        this._transactionsLoaded = true;
      })
      .catch(() => {
        this._transactionsLoaded = true;
      });
  }

  @action.bound
  async createWallet(type: WalletType, name?: string) {
    const data: CreateWalletRequest = { type };

    if (name) {
      data.name = name;
    }

    return api<WalletResponse>({
      method: 'post',
      path: `/wallet/create`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async importWallet(
    type: WalletType,
    privateKey: string,
    name = 'Imported Wallet',
  ) {
    const data: ImportWalletRequest = { type, privateKey, name };

    return api<WalletResponse>({
      method: 'post',
      path: `/wallet/import`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async updateWalletName(name: string) {
    return apiRaw<WalletResponse>({
      method: 'post',
      path: `/wallet/update`,
      data: {
        name,
        walletId: this.accountStore.currentWallet?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async deleteWallet() {
    return apiRaw<WalletResponse>({
      method: 'post',
      path: `/wallet/delete`,
      data: {
        userId: this.accountStore.lockedToken,
        walletId: this.accountStore.currentWallet?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @computed
  get balance() {
    return this._balance;
  }

  @computed
  get portfolio() {
    return this._portfolio;
  }

  @computed
  get assets() {
    return this._assets;
    // return this._assets.filter(
    //   (asset) =>
    //     asset.flags.displayable === true ||
    //     asset.flags.displayable === undefined,
    // );
  }

  @computed
  get transactions() {
    try {
      return this._transactions.filter((t) =>
        this.accountStore.isEvm
          ? allowedBlockchains.includes(t.blockchain)
          : t.blockchain ===
            (this.accountStore.network as unknown as Blockchain),
      );
    } catch {
      return [];
    }
  }

  @computed
  get assetsLoaded() {
    return this._assetsLoaded;
  }

  @computed
  get transactionsLoaded() {
    return this._transactionsLoaded;
  }

  @computed
  get recentWallets() {
    return this._recentWallets[this.accountStore.network as string] || [];
  }

  @action.bound
  addRecentWallet(wallet: string) {
    const recent = this.recentWallets.slice();
    const existIndex = recent.findIndex((w) => w[0] === wallet);
    if (existIndex !== -1) {
      recent.splice(existIndex, 1);
    } else if (recent.length >= 5) {
      recent.pop();
    }
    this._recentWallets = {
      ...this._recentWallets,
      [this.accountStore.network as string]: [[wallet, Date.now()], ...recent],
    };
    updateLocalStorageObject('recentWallets', this._recentWallets);
  }

  @action.bound
  errorHandler(error: AxiosError | any) {
    return this.accountStore.errorHandler(error);
  }
}
