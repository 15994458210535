import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { useStores } from '@hooks/useStores';
import useResponseHandler from '@hooks/useResponseHandler';
import { observer } from 'mobx-react-lite';
import {
  allowedEvmChainsDeprecated,
  chainName,
  walletToChain,
} from '@helpers/chains';
import { WalletType } from '../../types/enums';
import Preloader from '@components/common/Preloader';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '@helpers/formatters';
import usePopup from '@hooks/usePopup';
import uploadCloud from '@assets/icons/upload-cloud-teal.svg';
import plusCircle from '@assets/icons/plus-circle-teal.svg';
import BadgeIconNew from '@components/common/BadgeIconNew';
import info from '@assets/icons/info-circle-teal.svg';

const options = [
  {
    title: 'wallet.create-title',
    desc: 'wallet.create-desc',
    icon: plusCircle,
    operation: 'create',
  },
  {
    title: 'wallet.import-title',
    desc: 'wallet.import-desc',
    icon: uploadCloud,
    operation: 'import',
  },
];

const NewWalletOperation = observer(() => {
  const { t } = useTranslation();
  const { accountStore, walletStore } = useStores();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const handleResponse = useResponseHandler();
  const showPopup = usePopup();
  const [isShowPopup, setShowPopup] = useState<boolean>(false);

  const cancelLoading = () => {
    setIsLoading(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    walletStore
      .createWallet(state.type)
      .then((response) => {
        if (response && response.address) {
          if (state.type === WalletType.EVM)
            accountStore.setNetwork(walletToChain(state.type));
          accountStore
            .loadUser()
            .then((load) => {
              if (load && load.data) {
                accountStore.setCurrentWallet(response);
                navigate(PageRoutes.NEW_WALLET_KEY, { state });
              } else {
                handleResponse(response.response, true);
                cancelLoading();
              }
            })
            .catch((error) => {
              handleResponse(error.message, true);
              cancelLoading();
            });
        } else {
          handleResponse(response.response, true);
          cancelLoading();
        }
      })
      .catch((error) => {
        handleResponse(error.message, true);
        cancelLoading();
      });
  };

  const networks = useMemo(() => {
    if (!state?.type) {
      return '';
    }
    if (state.type === WalletType.EVM) {
      return allowedEvmChainsDeprecated
        .map((chain) => chainName(chain))
        .join(', ');
    }
    return walletToChain(state.type);
  }, [state]);

  const handleSelect = (operation: string) => {
    showPopup({
      message: (
        <div className="pb-3">
          <BadgeIconNew className="my-3">
            <img src={info} alt="info" />
          </BadgeIconNew>

          <div className="tx-18 tx-semibold">
            {t('wallet.confirm-operation', {
              operation: t(`wallet.${operation}`),
            })}
          </div>
          <div className="tx-14 px-3 tx-muted">
            {t('wallet.confirm-operation-desc', { networks })}
          </div>
        </div>
      ),
      buttons: [
        {
          text: capitalizeFirstLetter(t('wallet.' + operation)),
          type: 'primary',
          id: 'ok',
        },
        {
          text: t('common.cancel'),
          id: 'cancel',
        },
      ],
    }).then((result) => {
      if (result === 'ok') {
        if (operation === 'import') {
          navigate(PageRoutes.NEW_WALLET_KEY_IMPORT, { state });
        } else {
          handleSubmit();
        }
      }
    });
  };

  if (state?.type) {
    !isShowPopup && setShowPopup(true);
    if (!isShowPopup) handleSelect(state?.operation);
  }

  if (isLoading) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader
          inline
          iconSize={32}
          className="d-flex flex-column align-items-center"
          textClass="d-none"
          iconClass="d-block"
          text=""
        />
      </div>
    );
  }

  return (
    <div className="tx-center full-page d-flex flex-column">
      <h1 className="tx-24 mt-5">{t('wallet.add-wallet-title')}</h1>

      <div className="tx-muted tx-14 mb-5 px-4">
        {t('wallet.add-wallet-desc')}
      </div>

      <div>
        {options.map(({ title, desc, icon, operation }, index) => (
          <button
            className={`btn btn-semi-10 p-3 d-flex flex-column align-items-start justify-content-start tx-left wd-100p mb-3`}
            onClick={() =>
              navigate(PageRoutes.NEW_WALLET, { state: operation })
            }
            key={`type-selection-item-${index}`}
          >
            <BadgeIconNew className="mb-3">
              <img src={icon} alt={title} className="wd-20" />
            </BadgeIconNew>

            <div className="tx-16 tx-white tx-semibold mb-1">{t(title)}</div>

            <div className="tx-14 tx-muted tx-normal">{t(desc)}</div>
          </button>
        ))}
      </div>
    </div>
  );
});

export default NewWalletOperation;
