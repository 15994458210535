import FormattedNumber from '@components/common/FormattedNumber';
import { greaterThanOrEqual } from '@helpers/bignumber';
import { useStores } from '@hooks/useStores';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Placeholder from '@components/common/Placeholder';

const LimitOrdersTokenBalance = () => {
  const { tokenStore } = useStores();
  const { tokenDetails, tokenBalance } = tokenStore;
  const { t } = useTranslation();

  const balanceInUsd = useMemo(() => {
    return new BigNumber(tokenBalance?.tokenBalanceInUsd || 0)
      .toFixed(2)
      .split('.');
  }, [tokenBalance]);

  return (
    <div className="card">
      <div className="tx-15 mb-3">{t('common.balance-shrt')}</div>
      {tokenBalance ? (
        <div className="d-flex align-items-center tx-bold tx-28">
          <FormattedNumber value={balanceInUsd[0]} suffix="$" />
          <span className="tx-muted">.{balanceInUsd[1]}</span>
          <FormattedNumber
            value={tokenBalance?.pnlPercent}
            postfix="%"
            className={`${parseInt(tokenBalance?.pnlPercent || '0') === 0 ? 'd-none' : 'd-block'} mx-1 tx-13 tx-semibold ${tokenBalance?.pnlPercent && greaterThanOrEqual(tokenBalance.pnlPercent, 0) ? 'tx-success' : 'tx-danger'}`}
            withSign
          />
          <FormattedNumber
            value={tokenBalance?.pnlUsd}
            suffix="($"
            postfix=")"
            decimals={
              new BigNumber(tokenBalance?.pnlUsd || 0).isLessThan(0.01) ? 6 : 2
            }
            className={`${parseInt(tokenBalance?.pnlPercent || '0') === 0 ? 'd-none' : 'd-block'} tx-13 tx-semibold ${tokenBalance?.pnlPercent && greaterThanOrEqual(tokenBalance.pnlPercent, 0) ? 'tx-success' : 'tx-danger'}`}
          />
        </div>
      ) : (
        <Placeholder width={116} height={32} className="mt-1" />
      )}
      <FormattedNumber
        value={tokenBalance?.tokenBalance}
        postfix={tokenDetails?.quoteToken?.symbol}
      />
    </div>
  );
};

export default observer(LimitOrdersTokenBalance);
