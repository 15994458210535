import React, { useEffect, useMemo, useState } from 'react';
import ReferralBadge from '@pages/Referral/components/ReferralBadge';
import FormattedNumber from '@components/common/FormattedNumber';
import Progress from '@components/common/Progress';
import ReferralShare from '@pages/Referral/components/ReferralShare';
import ReferralQr from '@pages/Referral/components/ReferralQr';
import LabelValueCard from '@components/common/LabelValueCard';
import { RANK_RULES, ReferralLevelType } from '@pages/Referral/constants';
import ReferralListItem from '@pages/Referral/components/ReferralListItem';
import { observer } from 'mobx-react-lite';
import ReferralLevelModal from '@pages/Referral/components/ReferralLevelModal';
import ReferralClaimModal from '@pages/Referral/components/ReferralClaimModal';
import { useStores } from '@hooks/useStores';
import useResponseHandler from '@hooks/useResponseHandler';
import PageLoader from '@components/PageLoader';
import { ENV } from '../../constants';
import { ReferralLineResponse, ReferralProfileResponse } from '../../types/referral/referral-profile.response';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@helpers/numbers';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import ReferralWhalesModal from '@pages/Referral/components/ReferralWhalesModal';

const Referral = observer(() => {
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const { refData: data } = accountStore;
  const handleResponse = useResponseHandler();
  const [modalType, setModalType] = useState<ReferralLevelType | null>(null);
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);
  const [w, AmplitudeWrapper] = useAmplitude([
    AmplitudeEvent.REFERRAL_PROGRAM_SCREEN_VIEWED,
  ]);

  useEffect(() => {
    accountStore
      .getReferralData()
      .catch((response) => {
        handleResponse(response);
      });
  }, [triggerUpdate]);

  const totalTurnover = useMemo(() => {
    return data?.total30dUsdVolume || 0;
  }, [data]);

  const unclaimedProfit = useMemo(() => {
    return data?.wallets?.reduce((acc, wallet) => {
      return acc + parseFloat(wallet.balanceUsd);
    }, 0);
  }, [data]);

  const currentLevel = useMemo(() => {
    const { rank } = data ?? {};
    const curRank = Math.min(rank ?? 0, RANK_RULES.length - 1)
    return RANK_RULES[curRank];
  }, [JSON.stringify(data)]);

  const nextLevel = useMemo(() => {
    return RANK_RULES[currentLevel.rank + 1];
  }, [JSON.stringify(data)]);

  const referralLink = useMemo(() => {
    if (!data) {
      return '';
    }

    return `${ENV.BOT_URL}?start=${data.refCode}`;
  }, [data]);

  const progressBg = useMemo(() => {
    switch (currentLevel.rank) {
      case ReferralLevelType.BRONZE:
        return 'beige';
      case ReferralLevelType.SILVER:
        return 'silver';
      case ReferralLevelType.GOLD:
        return 'gold';
      case ReferralLevelType.PLATINUM:
        return 'white';
      case ReferralLevelType.DIAMOND:
        return 'diamond-gradient';
      case ReferralLevelType.MASTER:
      case ReferralLevelType.ULTRA:
        return 'master-gradient';
      default:
        return 'beige';
    }
  }, [currentLevel.rank]);

  const firstLine: ReferralLineResponse = useMemo(() => {
    const line = data?.lines.find((l) => l.line === 1);
    return (
      line || {
        line: 0,
        referralsCount: 0,
        total30dUsdVolume: 0,
      }
    );
  }, [data]);

  const secondLine: ReferralLineResponse = useMemo(() => {
    const line = data?.lines.find((l) => l.line === 2);
    return (
      line || {
        line: 0,
        referralsCount: 0,
        total30dUsdVolume: 0,
      }
    );
  }, [data]);

  if (!data) {
    return <PageLoader />;
  }

  return (
    <div className="py-4 pb-5">
      <h1 className="tx-28 mb-3">{t('common.referral-program')}</h1>
      <div className="card mb-4">
        <div className="d-flex flex-row justify-content-start align-items-center mb-3">
          <div className="wd-65 ht-65 mn-wd-65 mx-wd-65">
            <ReferralBadge type={currentLevel.rank} />
          </div>
          <div className="d-flex flex-column ms-3 wd-100p">
            <div className="tx-17 tx-semibold">{currentLevel.label}</div>
            <Progress
              value={nextLevel ? totalTurnover : 100}
              max={nextLevel ? nextLevel.min30dUsdVolume : 100}
              forceColor={progressBg}
              className="mt-2 wd-100p"
            />
            {nextLevel && (
              <div className="tx-12 tx-muted d-flex align-items-center">
                <span className="me-1">{formatNumber(totalTurnover, '$')}</span>
                {t('common.of')}
                <span className="mx-1">
                  {formatNumber(nextLevel.min30dUsdVolume || 0, '$')}
                </span>
                {t('referral.to')}
                <button
                  className="btn btn-transparent p-0 tx-right tx-capitalize tx-12 tx-muted text-decoration-underline ms-1"
                  onClick={() => setModalType(nextLevel.rank)}
                >
                  {nextLevel.label}
                </button>
              </div>
            )}
            {!nextLevel && (
              <div className="tx-12 tx-muted">{t('referral.max-level')}</div>
            )}
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-end">
          <div>
            <div className="tx-muted tx-12">{t('referral.available')}:</div>
            <FormattedNumber
              value={unclaimedProfit}
              suffix="$"
              floor
              className="tx-22 tx-semibold"
            />
          </div>
          <ReferralClaimModal
            data={data}
            unclaimedProfit={unclaimedProfit}
            onClaim={() => setTriggerUpdate((v) => ++v)}
          />
        </div>

        <div className="pt-3 mt-3 border-top border-semi-transparent d-flex gap-2">
          <LabelValueCard
            label={t('referral.personal-turnover')}
            value={data.personal30dUsdVolume}
            unit="$"
          />
          <LabelValueCard
            label={t('referral.referral-turnover')}
            value={data.team30dUsdVolume}
            unit="$"
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4 gap-3">
        <AmplitudeWrapper
          className="wd-100p"
          eventInput={AmplitudeEvent.REFERRAL_LINK_CLICKED}
        >
          <ReferralShare
            link={referralLink}
            text={`${t('referral.referral-id')}: ${data.refCode}`}
          />
        </AmplitudeWrapper>
        <AmplitudeWrapper
          className="wd-100p"
          eventInput={AmplitudeEvent.QR_CODE_CLICKED}
        >
          <ReferralQr link={referralLink} />
        </AmplitudeWrapper>
      </div>

      <ReferralWhalesModal link={data.whaleChatInviteLink} turnover={data.personalAllTimeUsdVolume} />

      <div className="card px-0 d-block py-0">
        <div className="flex-scroll gap-3 px-3">
          {RANK_RULES.map((level) => {
            return (
              <ReferralListItem
                level={level}
                isActive={currentLevel.rank === level.rank}
                onClick={w(
                  () => setModalType(level.rank),
                  AmplitudeEvent.REFERRAL_LEVEL_SELECTED,
                  { level: level.label },
                )}
                key={`referral-list-item-${level.rank}`}
              />
            );
          })}
        </div>
      </div>

      <div className="mb-4 tx-13 tx-muted pd-12 pb-0">
        {t('referral.alpha-tax')}
      </div>

      <div className="card tx-13 mb-5">
        <div className="tx-18 tx-semibold mt-1 mb-3">
          {t('referral.rewards')}
        </div>

        <div className="d-flex justify-content-between align-items-center tx-semibold mb-2">
          <div>{t('referral.invited-by-you')}</div>
          <div>
            {t('referral.referral-bonus')}:{' '}
            <span className="tx-success">
              {currentLevel.refRewardPercents[0]}%
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
          <LabelValueCard
            label=""
            value={`${firstLine.referralsCount} ${t('referral.users')}`}
          />
          <LabelValueCard
            label={t('referral.turnover-30d')}
            value={firstLine.total30dUsdVolume || 0}
            unit="$"
          />
        </div>

        <div className="d-flex justify-content-between align-items-center tx-semibold mb-2 mt-4">
          <div>{t('referral.invited-by-your-friends')}</div>
          <div>
            {t('referral.referral-bonus')}:{' '}
            <span className="tx-success">
              {currentLevel.refRewardPercents[1]}%
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
          <LabelValueCard
            label=""
            value={`${secondLine.referralsCount} ${t('referral.users')}`}
          />
          <LabelValueCard
            label={t('referral.turnover-30d')}
            value={secondLine.total30dUsdVolume || 0}
            unit="$"
          />
        </div>
      </div>

      <ReferralLevelModal
        type={modalType}
        onClose={() => setModalType(null)}
        valueNow={totalTurnover}
      />
    </div>
  );
});

export default Referral;
