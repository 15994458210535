import { MeResponse } from '../types/user/me.response';
import { LoginResponse } from '../types/user/login.response';
import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  computed,
} from 'mobx';
import api, { ApiProps, apiRaw, axiosInstance } from '@helpers/api';
import { AxiosError } from 'axios';
import { ConfirmRegistrationResponse } from 'types/user/confirm-registration.response';
import * as Sentry from '@sentry/react';
import { ENV } from '../constants';
import { TelegramStore } from '@stores/telegram-store';
import { init as amplitudeInit } from '@amplitude/analytics-browser';
import { isTMA } from '@telegram-apps/sdk-react';
import { isLocalhost } from '@helpers/device';
import { hasOwnProperty } from '@helpers/object';

export class AuthStore {
  private _telegramStore: TelegramStore;

  @observable
  private _accessToken: string | null = null;
  private _initData: string | null = null;
  private _refreshToken: string | null = null;
  private _userId: string | null = null;
  @observable
  private _me?: MeResponse;

  @observable
  private _isServiceMode?: boolean = ENV.TECH_WORK === 'true';

  constructor(telegramStore: TelegramStore) {
    this._telegramStore = telegramStore;

    const cachedUserId = localStorage.getItem('userId');
    const cachedAccessToken = localStorage.getItem('token');
    const cachedRefreshToken = localStorage.getItem('refreshToken');

    isTMA().then((result) => {
      telegramStore.setIsTMA(result);
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.code == 'ERR_CANCELED') return;
        if (error.response.status === 401) this._accessToken = null;
        return Promise.reject(error);
      },
    );

    makeAutoObservable(this);

    reaction(
      () => this.accessToken,
      () => {
        if (this.accessToken) {
          axiosInstance.defaults.headers.common['Authorization'] =
            `Bearer ${this.accessToken}`;

          localStorage.setItem('token', this.accessToken);
        }
      },
    );

    reaction(
      () => this.refreshToken,
      () => {
        if (this.refreshToken) {
          localStorage.setItem('refreshToken', this.refreshToken);
        }
      },
    );

    reaction(
      () => !this.accessToken && !!(this.refreshToken || this.initData),
      async () => {
        if (!this.accessToken) await this.checkMe();
      },
    );

    reaction(
      () => this._userId,
      () => {
        this._userId && localStorage.setItem('userId', this._userId);

        !isLocalhost() &&
          Sentry.init({
            dsn: 'https://547790eb834f4800cdeee9f2b899d4b1@o4507446582312960.ingest.de.sentry.io/4507446614687824',
            integrations: [
              Sentry.browserTracingIntegration(),
              Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
                networkDetailAllowUrls: [
                  window.location.origin,
                  `${ENV.API_URL}/api/v1`,
                ],
              }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              /^https:\/\/alpha-server\.pro/,
              /^https:\/\/stage\.wallet-ui\.pages\.dev/,
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          });

        !isLocalhost() &&
          amplitudeInit(
            'aaed9812a1e8e083bfee2bd1e1529cf6',
            String(localStorage.getItem('userId')),
            { defaultTracking: true },
          );
      },
    );

    reaction(
      () => telegramStore.isSDKLoaded,
      () => {
        if (hasOwnProperty(window, 'Telegram') && window.Telegram.WebApp) {
          const { initData: tgInitData }: { initData: string } =
            window.Telegram.WebApp;
          const { token, force } = Object.fromEntries(
            new URLSearchParams(location.search),
          );

          !!cachedUserId && (this._userId = cachedUserId);
          !!force && (this._accessToken = null);
          this._refreshToken = token || cachedRefreshToken;

          if (tgInitData && this.isTMA) this._initData = tgInitData;

          this._accessToken = cachedAccessToken;
        } else {
          const token = new URLSearchParams(location.search).get('token');
          if (token) {
            this._refreshToken = token;
          } else {
            this._accessToken = cachedAccessToken;
            this._refreshToken = cachedRefreshToken;
          }
        }
      },
      { fireImmediately: true },
    );
  }

  @computed
  get isTMA() {
    const initData = window.Telegram?.WebApp?.initData;
    const isTelegramApp = !!initData?.length || this._telegramStore.isTMA;
    
    console.log('AuthStore: isTMA check', {
      initDataExists: !!initData?.length,
      telegramStoreIsTMA: this._telegramStore.isTMA,
      result: isTelegramApp
    });
    
    return isTelegramApp;
  }

  @computed
  get isLogged() {
    return !!(this.accessToken && (this.initData || this.refreshToken));
  }

  @computed
  get isServiceMode() {
    // Пока отключаем вручную пока не найдем способ обновлять переменной окружения;
    return false;
  }

  @computed
  get accessToken() {
    return this._accessToken;
  }

  @computed
  get initData() {
    return this._initData;
  }

  @computed
  get refreshToken() {
    return this._refreshToken;
  }

  @computed
  get isPinEnabled() {
    return !!(
      this._me?.pinCodeEnabled ??
      !['false', null].includes(localStorage.getItem('pinEnabled'))
    );
  }

  @computed
  get isActivated() {
    return this._me?.isActivated;
  }

  @computed
  get userId() {
    return this._me?.userId;
  }

  @computed
  get isBot() {
    const initData = window.Telegram?.WebApp?.initData;

    return !!initData?.length;
  }

  @computed
  get apiAuthProps() {
    const initData = window.Telegram?.WebApp?.initData;

    return (path: string, dataExt: Record<string, any> = {}): ApiProps =>
      this.isBot
        ? {
            method: 'post',
            path: `/bot${path}`,
            data: { ...dataExt, initData },
          }
        : {
            method: 'post',
            path: `/user${path}`,
            data: { ...dataExt, refreshToken: this.refreshToken },
          };
  }

  @action.bound
  async checkMe() {
    const request = this.apiAuthProps('/me');
    localStorage.removeItem('userId');

    return apiRaw<MeResponse>(request)
      .then(({ data }) => {
        this._me = data;
        this._userId = data.userId;

        const pinEnabled = !['null', 'false'].includes(
          String(data.pinCodeEnabled),
        );
        localStorage.setItem('pinEnabled', pinEnabled.toString());

        if (pinEnabled === false) this.login();
      })
      .catch((response) => {
        this._me = undefined;
      });
  }

  @action.bound
  async login(pin?: string) {
    const request = this.apiAuthProps('/login', { pin });

    return api<LoginResponse>(request)
      .then((response) => {
        if (response.accessToken) {
          this._accessToken = response.accessToken;
        }
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action.bound
  setPinEnabled(v: boolean) {
    if (this._me) this._me.pinCodeEnabled = v;
  }

  @action.bound
  setServiceMode(v: boolean) {
    this._isServiceMode = v;
  }

  @action.bound
  setRefreshToken(v: string) {
    this._refreshToken = v;
    localStorage.setItem('refreshToken', v);
  }

  @action.bound
  async register(pin: string, mnemonic?: string) {
    const request = this.apiAuthProps('/register/confirm', { mnemonic, pin });

    return api<ConfirmRegistrationResponse>(request)
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action.bound
  resetStore() {
    this._accessToken = null;
    this._refreshToken = null;
    this._me = undefined;
    this._userId = null;
    this._initData = null;
  }
}
