import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import Onboarding from '@pages/Onboarding';
import TechJobs from '@components/TechJobs';
import loading from '@assets/lottie/preloader-white.json';
import Lottie from 'lottie-react';

const Welcome = observer(() => {
  const { telegramStore, authStore, accountStore } = useStores();

  const { queryToken: token } = telegramStore;
  const { isLogged, isActivated, isServiceMode } = authStore;
  const { checkDone, wallets, redirectTo } = accountStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (isServiceMode) return;

    if (!checkDone) {
      return;
    }

    if (wallets.length) {
      navigate(redirectTo ?? PageRoutes.WALLET, { replace: true });
    }
  }, [wallets, checkDone]);

  if (isServiceMode) return <TechJobs />;

  const isTMA = useMemo(() => !!window.Telegram?.WebApp?.initData?.length, []);

  if (token?.length && isActivated !== false) {
    return (
      <div className="full-page d-flex align-content-center justify-content-center py-5 px-3">
        <Lottie animationData={loading} loop={true} />
      </div>
    );
  } else if (
    (!isLogged && isActivated === false) ||
    (!isTMA && !isLogged && !token) ||
    (!isTMA && !isLogged && token && checkDone)
  ) {
    return <Onboarding />;
  } else if (!checkDone || isActivated === undefined || checkDone < 400) {
    return (
      <div className="full-page d-flex align-content-center justify-content-center py-5 px-3">
        <Lottie animationData={loading} loop={true} />
      </div>
    );
  } else if (isActivated === false) {
    return <Onboarding isCreateWallet />;
  } else
    return (
      <div className="full-page d-flex align-content-center justify-content-center py-5 px-3">
        <Lottie animationData={loading} loop={true} />
      </div>
    );
});

export default Welcome;
