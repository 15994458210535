import React, { useEffect, useMemo, useRef, useState } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import InputGroup from '@components/common/InputGroup';
import Toggle from 'react-toggle';
import { IOrderSettings, WalletBalance } from '../../../../types';
import OrderSettingsModal from '@pages/LimitOrders/components/OrderSettingsModal';
import swapArrow from '@assets/images/switch-values.svg';
import GasPrice from '@components/common/GasPrice';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import { defaultOrderSettings } from '@stores/settings-store-old';
import TakeProfitValue from '@pages/LimitOrders/components/OrderValue/TakeProfitValue';
import StopLossValue from '@pages/LimitOrders/components/OrderValue/StopLossValue';
import TPSLTriggerGroup from '@pages/LimitOrders/components/OrderValue/TPSLTriggerGroup';
import cn from 'classnames';
import { ChainId } from '../../../../constants';
import { BaseChainToken, Blockchain } from 'types/enums';
import useInterval from '@hooks/useInterval';
import Placeholder from '@components/common/Placeholder';

interface OrderValueProps {
  balance: WalletBalance | null;
  settings?: IOrderSettings;
  setSettings?: (val: IOrderSettings) => void;
  amount: string | number | null;
  amountChange: (v: string | number) => void;
  amountUsd: string | number | null;
  amountUsdChange: (v: string | number) => void;
  predefined?: number[];
  isPredefinedUsd?: boolean;
  noPredefined?: boolean;
  noBalance?: boolean;
  title?: string;
  symbol?: string;
  withName?: string;
}

const defaultPredefined = [0.05, 0.1, 0.25, 0.5];

const OrderValue = observer((props: OrderValueProps) => {
  const { t } = useTranslation();
  const {
    balance,
    amount,
    amountChange,
    amountUsd,
    amountUsdChange,
    predefined,
    isPredefinedUsd = false,
    noBalance = false,
    noPredefined = false,
    title = t('common.value'),
    symbol,
    settings: propsSettings,
    setSettings,
  } = props;

  const { tokenStore, ordersStore, settingsStore, tokenTrade } = useStores();
  const { network } = ordersStore;
  const { tokenDetails: token, gas, tokenUsdPrice } = tokenStore;
  const {
    isAlpha,
    isBuy,
    chain,
    currency,
    isStopLossesEnabled,
    isTakeProfitsEnabled,
    emptyInputsLightOn,
  } = tokenTrade;
  const { orderSettings } = settingsStore;
  const changeLock = useRef(false);

  const settings = orderSettings || defaultOrderSettings(chain as Blockchain);

  const [isUsd, setIsUsd] = useState(true);
  const initTime = useRef(Date.now());

  // useEffect(() => {
  //   if (!currency) {
  //     setIsUsd(false);
  //   }
  // }, [currency]);

  // Check if currency is loaded
  useInterval(() => {
    // If more than 4 seconds passed - switch to native mode
    if (Date.now() - initTime.current > 3900) {
      setIsUsd(false);
      initTime.current = Infinity;
      return;
    }
    // Wait for 2 seconds before checking
    if (Date.now() - initTime.current > 1500) {
      // If currency is not loaded
      if (!currency) {
        // Switch to native mode
        setIsUsd(false);
        // Get currency again
        tokenTrade.getCurrency().then((response) => {
          // @ts-ignore
          const base = BaseChainToken[chain];
          // If currency is loaded and found in response return to USD mode
          // @ts-ignore
          if (response[base]) {
            initTime.current = Infinity;
            if (amount) {
              handleChange(amount, isBuy ? 'native' : 'token');
            }
            setIsUsd(true);
          }
        });
      } else {
        initTime.current = Infinity;
        if (amount) {
          handleChange(amount, isBuy ? 'native' : 'token');
        }
        setIsUsd(true);
      }
    }
  }, !currency && initTime.current !== Infinity ? 1000 : null);

  useEffect(() => {
    if (currency && initTime.current !== Infinity) {
      setIsUsd(true);
      initTime.current = Infinity;
    }
  }, [currency, initTime.current]);

  useEffect(() => {
    if (currency && isUsd) {
      initTime.current = Infinity;
    }
  }, [currency, isUsd]);

  const isEther = useMemo(() => {
    return network === ChainId.ETHER;
  }, [network]);

  const updateSettings = (val: IOrderSettings) => {
    if (setSettings) {
      setSettings(val);
      return;
    }
    settingsStore.setOrderSettings(val);
  };

  const ethValue = useMemo(() => {
    if (amount === null || amount === undefined) {
      return null;
    }
    return new BigNumber(amount);
  }, [amount]);

  const handleChange = (
    v: string | number | null,
    force?: 'usd' | 'token' | 'native' | false,
  ) => {
    const bn = new BigNumber(v || 0);
    if (force === 'usd') {
      changeLock.current = true;
      amountUsdChange(bn.toString());
      amountChange(bn.dividedBy(currency || 1).toString());
    }
    if (force === 'token') {
      changeLock.current = true;
      amountChange(bn.toString());
      amountUsdChange(bn.multipliedBy(tokenUsdPrice).toString());
    }
    if (force === 'native') {
      changeLock.current = true;
      amountChange(bn.toString());
      amountUsdChange(bn.multipliedBy(currency || 0).toString());
    }

    if (!changeLock.current) {
      const curr = isBuy || noBalance ? currency : tokenUsdPrice;

      if (isUsd) {
        amountUsdChange((bn ?? '0').toString());
        amountChange(bn.dividedBy(curr || 1).toString());
      } else {
        amountChange((bn ?? '0').toString() ?? 0);
        amountUsdChange(bn.multipliedBy(curr || 0).toString());
      }
    }
    changeLock.current = false;
  };

  const handlePercent = (percent: number) => {
    const value = new BigNumber(balance?.balance || 0)
      .multipliedBy(percent)
      .dividedBy(100)
      .toString();
    handleChange(value, 'token');
  };

  const switchMode = () => {
    if (!currency) return;

    changeLock.current = true;

    setIsUsd((prev) => !prev);
  };

  return (
    <>
      <div className="rounded-3 overflow-hidden mt-4">
        <div className={cn(['card', 'rounded-top', 'rounded-3-px'])}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="tx-17 tx-semibold">{title}</div>
            {!noBalance && isEther && (
              <GasPrice gas={gas} className="ms-auto me-2" />
            )}
            <OrderSettingsModal
              data={propsSettings || settings}
              updateSettings={updateSettings}
            />
          </div>
          {!noBalance && (
            <div className="d-flex align-items-center justify-content-start tx-muted mb-3 tx-13">
              <div>{t('common.balance')}</div>
              <span
                className="cur-pointer"
                onClick={() =>
                  handleChange(
                    balance?.balance || 0,
                    isBuy ? 'native' : 'token',
                  )
                }
              >
                <FormattedNumber
                  value={balance?.balance}
                  decimals={4}
                  subZeros
                  postfix={balance?.token}
                  className="tx-white ms-1 text-decoration-underline"
                />
                <FormattedNumber
                  value={balance?.balanceInUsd}
                  className="tx-white ms-1"
                  suffix="($"
                  postfix=")"
                  noSpace
                  floor
                  subZeros
                />
              </span>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center gap-2">
            {(!balance || !token || !currency) ? (
              <Placeholder width={100} height={48} className="wd-100p mb-2" fullWidth />
            ) : (
              <>
                {!isUsd && (
                  <>
                    <InputGroup
                      value={amount && +amount ? amount : null}
                      onChange={(v) => handleChange(v)}
                      placeholder={`0 ${balance?.token || token?.baseToken.symbol || ''}`}
                      unit={balance?.token || token?.baseToken.symbol || symbol}
                      isNumeric
                      isInValid={
                        emptyInputsLightOn &&
                        (ethValue
                          ? ethValue.isGreaterThan(balance?.balance || 0) ||
                            ethValue.isLessThanOrEqualTo(0)
                          : false)
                      }
                      className="ht-50"
                    />
                  </>
                )}
                {isUsd && (
                  <>
                    <InputGroup
                      value={amountUsd && +amountUsd ? amountUsd : null}
                      onChange={(v) => handleChange(v)}
                      placeholder={`$0`}
                      unit="$"
                      isNumeric
                      isInValid={
                        emptyInputsLightOn &&
                        (ethValue
                          ? ethValue.isGreaterThan(balance?.balance || 0) ||
                            ethValue.isLessThanOrEqualTo(0)
                          : false)
                      }
                      className="ht-50"
                      disabled={!currency}
                    />
                  </>
                )}
              </>
            )}
            {/*<InputGroup*/}
            {/*  value={amountUsd}*/}
            {/*  onChange={amountUsdChange}*/}
            {/*  placeholder="$100"*/}
            {/*  unit="$"*/}
            {/*  isNumeric*/}
            {/*  isInValid={usdValue ? usdValue.isGreaterThan(balance?.balanceInUsd || 0) || usdValue.isLessThanOrEqualTo(0) : false}*/}
            {/*  disabled*/}
            {/*/>*/}
          </div>
          <div className={`${!currency || !balance ? 'd-none' : 'd-flex'} flex-row align-items-center justify-content-between gap-2 mt-2`}>
            <div
              className="card bg-semi-transparent-10 py-1 ht-35 tx-14 justify-content-center tx-muted px-2 wd-100p cur-pointer"
              onClick={switchMode}
            >
              {!isUsd && (
                <FormattedNumber
                  value={amountUsd || 0}
                  suffix="$"
                  className="ms-1"
                  subZeros
                />
              )}
              {isUsd && (
                <FormattedNumber
                  value={amount || 0}
                  decimals={ethValue?.isGreaterThan(1000) ? 0 : undefined}
                  postfix={balance?.token || symbol}
                  className="ms-1"
                  subZeros
                />
              )}
            </div>
            <img
              src={swapArrow}
              alt="Swap"
              className="cur-pointer wd-30 mn-wd-30"
              onClick={switchMode}
            />
          </div>

          {!noPredefined && (
            <div className={`${isPredefinedUsd && !currency ? 'd-none' : 'd-flex'} justify-content-between align-items-center wd-100p gap-2 mt-3`}>
              {isPredefinedUsd &&
                predefined?.map((a) => (
                  <button
                    className="btn btn-semi-10 py-2 px-1 justify-content-center align-items-center wd-100p tx-13"
                    onClick={() => handleChange(a, 'usd')}
                    key={a}
                  >
                    ${a}
                  </button>
                ))}
              {!isPredefinedUsd &&
                predefined?.map((a) => (
                  <button
                    className="btn btn-semi-10 py-2 px-1 justify-content-center align-items-center wd-100p tx-13"
                    onClick={() => handlePercent(a)}
                    key={a}
                  >
                    {a}%
                  </button>
                ))}
              {!predefined &&
                defaultPredefined.map((a) => (
                  <button
                    className="btn btn-semi-10 py-2 px-1 justify-content-center align-items-center wd-100p tx-13"
                    onClick={() => handleChange(a, false)}
                    key={a}
                  >
                    {a} {balance?.token || ''}
                  </button>
                ))}
            </div>
          )}
          {isBuy && !isAlpha && (
            <div className="d-flex mt-2">
              <div className="d-flex align-items-center gap-2 wd-50p">
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={isTakeProfitsEnabled}
                  onChange={(e) =>
                    tokenTrade.switchTakeProfits(e.target.checked)
                  }
                />
                <span className="tx-white tx-13 tx-bold">
                  {t('order.take-profit')}
                </span>
              </div>

              <div className="d-flex align-items-center gap-2 wd-50p">
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={isStopLossesEnabled}
                  onChange={(e) =>
                    tokenTrade.switchStopLosses(e.target.checked)
                  }
                />
                <span className="tx-white tx-13 tx-bold">
                  {t('order.stop-loss')}
                </span>
              </div>
            </div>
          )}
        </div>
        {!isAlpha && isBuy && (
          <>
            <TPSLTriggerGroup />
            <TakeProfitValue />
            <StopLossValue />
          </>
        )}
      </div>
    </>
  );
});

export default OrderValue;
