import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import TokenLogo from '@components/common/TokenLogo';
import { dexLogo, dexName } from '@helpers/chains';
import Vibrutton from '@components/common/Vibrutton';
import searchIcon from '@assets/icons/search.svg';
import IconWithLoading from '@components/common/IconWithLoading';
import times from '@assets/icons/times-gray.svg';
import { solanaRegex } from '../../../../constants';
import { Dex, PairType } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import { useNavigate, useParams } from 'react-router-dom';
import { FlipRouteParams } from '@stores/flip-store';
import useNotification from '@hooks/useNotification';
import { ResearchPairResponse } from 'types/research/research-pair.response';
import { OrdersSummaryResponse } from 'types/order/orders-summary.response';

const SolanaFlipTopBar = observer(() => {
  const { t } = useTranslation();
  const { flipStore, ordersStore } = useStores();
  const { tokenDetails, isSearching, error } = flipStore;
  const { summary } = ordersStore;
  const params = useParams<FlipRouteParams>();
  const navigate = useNavigate();
  const notify = useNotification();

  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState<string>('');

  const recentTokens = useMemo(() => {
    const _summary = summary.filter(
      ({ pairType }) =>
        pairType === PairType.RAY_V4 ||
        pairType === PairType.PUMPFUN ||
        pairType === PairType.PUMPSWAP ||
        pairType === PairType.RAY_CP,
    );
    const summaryWithoutCur = _summary.filter(
      ({ tokenAddress }) => tokenAddress !== tokenDetails?.quoteToken.address,
    );
    return tokenDetails ? [tokenDetails, ...summaryWithoutCur] : _summary;
  }, [summary, tokenDetails]);

  useEffect(() => {
    if (!recentTokens.length) {
      ordersStore.loadSummary();
    }
  }, [recentTokens.length]);

  useEffect(() => {
    if (search && solanaRegex.test(search)) {
      navigate(`/solana-flip/${search}`);
    }
  }, [search]);

  const isInvalid = useMemo(() => {
    return search.length > 31 && !solanaRegex.test(search);
  }, [search]);

  const dexTextColor = useCallback((dex: Dex) => {
    switch (dex) {
      case Dex.RAYDIUM:
        return 'tx-raydium';
      case Dex.PUMPFUN:
        return 'tx-pumpfun';
      default:
        return 'tx-muted';
    }
  }, []);

  useEffect(() => {
    if (!showSearch) {
      setSearch('');
    }
  }, [showSearch]);

  useEffect(() => {
    // If no token details, no recent tokens and no address in params, show search
    const ruleOne = !tokenDetails && !recentTokens?.length && !params.address;
    if (ruleOne) {
      setShowSearch(true);
    }
    // If no token details and no address in params, navigate to first token from recent tokens
    if (!ruleOne && !tokenDetails && recentTokens?.length && !params.address) {
      navigate(`/solana-flip/${recentTokens[0]?.pairAddress}`, {
        replace: true,
      });
    }
    // Otherwise just chill
  }, [tokenDetails, recentTokens, params, isSearching]);

  useEffect(() => {
    if (
      showSearch &&
      !isSearching &&
      [tokenDetails?.pairAddress, tokenDetails?.quoteToken.address].includes(
        search,
      )
    ) {
      setShowSearch(false);
    }
  }, [isSearching, tokenDetails, showSearch, search]);

  useEffect(() => {
    if (error) {
      const message =
        error.message === 'Get pair details error'
          ? t('errors.trading.raydium-v4-not-found')
          : error.message;
      notify(t(message), { type: 'danger' });
    }
  }, [error]);

  return (
    <div className="scrollable-container mb-3 pos-relative solana-flip-topbar">
      <div className="scrollable-list no-select">
        {recentTokens?.map((item, index) => {
          const isMigrated = (item as ResearchPairResponse).metadata?.migratedFromPumpfun;
          return (
          <Vibrutton
            className={cn([
              item.pairAddress === tokenDetails?.pairAddress ||
              params.address === item.pairAddress
                ? 'bg-secondary border border-semi-transparent'
                : 'bg-transparent border border-dark',
              'py-1 ps-2 pe-3',
              'd-flex flex-row align-items-center',
              'rounded-30',
              'scrollable-item',
              'lh-2',
              'cur-pointer',
              'tx-white',
              'tx-left',
            ])}
            key={`sol-flip-recent-${item.pairAddress}`}
            onClick={() => navigate(`/solana-flip/${item.pairAddress}`)}
          >
            <TokenLogo
              address={
                (item as OrdersSummaryResponse).tokenAddress ||
                ((item as ResearchPairResponse).quoteToken?.address ?? '')
              }
              name={
                (item as OrdersSummaryResponse).tokenSymbol ||
                ((item as ResearchPairResponse).quoteToken?.symbol ?? '')
              }
              chain={item.blockchain}
              size={30}
              className="border border-semi-transparent rounded-30"
              hideChain
              useName
            />
            <div className="ms-2 pointer-events-none">
              <div className="tx-14 mn-wd-55">
                {(item as OrdersSummaryResponse).tokenName ||
                  ((item as ResearchPairResponse).quoteToken?.name ?? '')}
              </div>
              <div className="tx-10 tx-uppercase d-flex align-items-center">
                {isMigrated && (
                  <>
                    <img src={dexLogo(Dex.PUMPFUN)} alt="pumpfun" width={16} />
                    <span className="tx-muted tx-14 mx-1">{'>'}</span>
                  </>
                )}
                <img src={dexLogo(item.dex)} alt={dexName(item.dex)} width={isMigrated ? 16 : 12} />
                {!isMigrated && (
                  <span className={dexTextColor(item.dex)}>
                    {dexName(item.dex)}
                  </span>
                )}
              </div>
            </div>
          </Vibrutton>
        )})}
      </div>
      <Vibrutton
        className={`btn btn-secondary rounded-30 border border-semi-transparent ht-45 wd-45 p-0 d-flex align-items-center justify-content-center search-button ${showSearch ? 'op-0' : ''}`}
        onClick={() => setShowSearch((v) => !v)}
      >
        <img src={searchIcon} alt="search" width={20} />
      </Vibrutton>

      <div
        className={`solana-search-input ${showSearch ? 'd-flex' : 'd-none'} align-items-center justify-content-between absolute-item pos-absolute px-3 bg-dark z-index-50`}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={searchIcon}
            alt="search input"
            className="mn-wd-20 mx-wd-20"
          />
        </div>
        {showSearch && (
          <input
            type="text"
            className={`form-control py-1 bg-transparent wd-100p ${isInvalid ? 'is-invalid' : ''}`}
            placeholder={t('trading.token-or-pair-address')}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            autoFocus
          />
        )}
        <Vibrutton
          className="btn btn-secondary bg-transparent border-0 ht-30 wd-30 p-0 d-flex align-items-center justify-content-center"
          onClick={() => setShowSearch((v) => !v)}
        >
          <IconWithLoading isLoading={isSearching} className="tx-20 lh-1" />
          <img src={times} alt="search" width={20} />
        </Vibrutton>
      </div>
    </div>
  );
});

export default SolanaFlipTopBar;
