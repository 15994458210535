import { useEffect } from 'react';
import { backButton, useSignal, isTMA } from '@telegram-apps/sdk-react';

interface BackButtonProps {
  onClick?: () => void;
}

/**
 * Component which controls the Back Button visibility.
 */
export function BackButton({ onClick }: BackButtonProps) {
  const isTelegram = isTMA('simple');

  // Add debugging
  useEffect(() => {
    console.log('BackButton: isTMA check result:', isTelegram);
  }, [isTelegram]);

  if (!isTelegram) {
    return null;
  }

  const isVisible = useSignal(backButton.isVisible);
  const isClickable = useSignal(backButton.onClick.isAvailable);
  const isOffClickable = useSignal(backButton.offClick.isAvailable);

  useEffect(() => {
    const onClickListener = () => {
      if (onClick) {
        onClick();
      }
      return;
    };
    if (isClickable) {
      backButton.onClick(onClickListener);
    }
    return () => {
      if (isOffClickable) {
        backButton.offClick(onClickListener);
      }
    }
  }, [isClickable, isOffClickable, onClick]);

  useEffect(() => {
    console.log('The button is', isVisible ? 'visible' : 'invisible');
  }, [isVisible]);

  useEffect(() => {
    console.log('BackButton: Attempting to show back button');
    backButton.show();
    return () => {
      backButton.hide();
    };
  }, []);

  return null;
}
