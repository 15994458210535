import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  referralRoutes,
  PageRoutes,
  portfolioRoutes,
  profileRoutes,
  researchRoutes,
  tokenTradeRoutes,
} from '../../constants';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Wallet } from './icons/portfolio.svg';
import { ReactComponent as Research } from './icons/research.svg';
import { ReactComponent as TokenTrade } from './icons/swap.svg';
import { ReactComponent as Profile } from './icons/profile.svg';
import { ReactComponent as Group } from './icons/group.svg';
import { isIOS } from '@helpers/device';
import { vibrate } from '@helpers/webApp';
import { useTranslation } from 'react-i18next';
import { useAmplitude, AmplitudeEvent } from '@hooks/useAmplitude';

const BottomNav = observer(() => {
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const initTop = useRef(0);
  const currentTop = useRef(0);
  const pageScrolled = useRef(false);
  const [sc, setSc] = useState(0);
  const [isInit, setIsInit] = useState(false);

  const handleClassName = (route: string | PageRoutes) => {
    let result =
      'nav-bar-item btn btn-link d-flex flex-column justify-content-center align-items-center cur-pointer wd-100p tx-10 text-decoration-none';
    const activeColor = ' tx-primary';
    if (
      portfolioRoutes.includes(route) &&
      portfolioRoutes.some((r) => location.pathname.includes(r))
    ) {
      result += activeColor;
    } else if (
      researchRoutes.includes(route) &&
      researchRoutes.some((r) => location.pathname.includes(r))
    ) {
      result += activeColor;
    } else if (
      referralRoutes.includes(route) &&
      referralRoutes.some((r) => location.pathname.includes(r))
    ) {
      result += activeColor;
    } else if (
      profileRoutes.includes(route) &&
      profileRoutes.some((r) => location.pathname.includes(r))
    ) {
      result += activeColor;
    } else if (
      tokenTradeRoutes.includes(route) &&
      tokenTradeRoutes.some((r) => location.pathname.includes(r))
    ) {
      result += activeColor;
    } else {
      result += ' tx-muted';
    }
    return result;
  };

  const handleClick = (route: PageRoutes) => () => {
    vibrate('medium');

    if (route.includes(location.pathname as PageRoutes)) {
      document
        .querySelector('#main-layout')
        ?.scrollTo({ top: 1, behavior: 'smooth' });
    }

    navigate(route);
  };

  useEffect(() => {
    if (ref.current && initTop.current === 0 && isInit) {
      initTop.current = ref.current.getBoundingClientRect().top;
      currentTop.current = ref.current.getBoundingClientRect().top;
    }
  }, [ref, initTop, currentTop, isInit]);

  useEffect(() => {
    if (!isInit) return;
    const onScroll = () => {
      if (ref.current) {
        setSc(window.scrollY);
        pageScrolled.current =
          window.scrollY >= document.body.scrollHeight - window.innerHeight - 1;
      }
    };
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [ref, isInit]);

  useEffect(() => {
    if (isInit) return;
    window.scrollTo({ top: 0 });
    setSc(0);
    setTimeout(() => {
      setIsInit(true);
    }, 500);
  }, [isInit]);

  useEffect(() => {
    setIsInit(false);
  }, [location.pathname]);

  return (
    <div
      id="bottom-nav"
      className={`${isIOS() ? 'with-notch' : ''} ${
        sc >= 10 &&
        window.scrollY >= document.body.scrollHeight - window.innerHeight - 1
          ? 'minimize'
          : ''
      }`}
      ref={ref}
    >
      <div
        className={handleClassName(PageRoutes.WALLET)}
        onClick={w(
          handleClick(PageRoutes.WALLET),
          AmplitudeEvent.WALLET_TAB_CLICKED,
        )}
      >
        <Wallet />
        <span className="mt-1">{t('common.wallet')}</span>
      </div>
      <div
        className={handleClassName(PageRoutes.RESEARCH)}
        onClick={w(
          handleClick(PageRoutes.RESEARCH),
          AmplitudeEvent.RESEARCH_TAB_CLICKED,
        )}
      >
        <Research />
        <span className="mt-1">{t('common.research')}</span>
      </div>
      <div
        onClick={w(
          handleClick(PageRoutes.LIMIT_ORDERS),
          AmplitudeEvent.TRADE_TAB_CLICKED,
        )}
        className={handleClassName(PageRoutes.LIMIT_ORDERS)}
        id="limit-orders-link"
      >
        <span className="tx-primary">
          <TokenTrade />
        </span>
        <span>{t('common.trade')}</span>
      </div>
      <div
        onClick={w(
          handleClick(PageRoutes.REFERRAL),
          AmplitudeEvent.TEAM_TAB_CLICKED,
        )}
        className={handleClassName(PageRoutes.REFERRAL)}
      >
        <Group />
        <span className="mt-1">{t('common.referrals')}</span>
      </div>
      <div
        onClick={w(
          handleClick(PageRoutes.PROFILE),
          AmplitudeEvent.PROFILE_TAB_CLICKED,
        )}
        className={handleClassName(PageRoutes.PROFILE)}
      >
        <Profile />
        <span className="mt-1">{t('common.settings')}</span>
      </div>
    </div>
  );
});

export default BottomNav;
