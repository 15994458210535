import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsAndConditionsNote = () => {
  const { t } = useTranslation();

  return (
    <div className="tx-muted mt-2 mb-3 tx-center tx-12 mx-3">
      {t('onboarding.terms-note')}{' '}
      <a
        href="https://alpha-dex.io/termsconditions"
        className="tx-muted"
        target="_blank"
        rel="noreferrer"
      >
        <strong>{t('onboarding.terms-link')}</strong>
      </a>
      {' '}{t('common.and')}{' '}
      <a
        href="https://alpha-dex.io/privacypolicy"
        className="tx-muted"
        target="_blank"
        rel="noreferrer"
      >
        <strong>{t('onboarding.privacy-link')}</strong>
      </a>
    </div>
  );
};

export default TermsAndConditionsNote;
