import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ChainId, ethRegex } from '../../constants';
import useDebounce from '@hooks/useDebounce';
import { useStores } from '@hooks/useStores';
import BadgeIcon from '@components/common/BadgeIcon';
import Preloader from '@components/common/Preloader';
import { HotToken } from '../../types';
import useResponseHandler from '@hooks/useResponseHandler';
import ResearchItem from '@pages/Research/components/ResearchItem';
import { hasOwnProperty } from '@helpers/object';
import { useTranslation } from 'react-i18next';
import { InviteLink } from '../../types/research/invite-links.response';
import ResearchApproveModal from '@pages/Research/components/ResearchApproveModal';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import ResearchItemTron from '@pages/Research/components/ResearchItemTron';
import NetworkSelectionPill from '@components/NetworkSelectionPill';
import ResearchWhaleSignals from './components/ResearchWhaleSignals';
import AlphaLink from '@components/common/AlphaLink';
import BigNumber from 'bignumber.js';
import { calcAdjustedPercent } from '@helpers/calculations';
import usePersistentScroll from '@hooks/usePersistentScroll';
import ResearchItemSkeleton from '@components/skeletons/ResearchItemSkeleton';
import Cloner from '@components/common/Cloner';

const bell = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.79514 17.5C8.38275 18.0187 9.15462 18.3334 10 18.3334C10.8454 18.3334 11.6172 18.0187 12.2048 17.5M15 6.66669C15 5.3406 14.4732 4.06884 13.5355 3.13115C12.5978 2.19347 11.3261 1.66669 10 1.66669C8.67391 1.66669 7.40214 2.19347 6.46446 3.13115C5.52678 4.06884 5 5.3406 5 6.66669C5 9.24184 4.35039 11.005 3.62472 12.1712C3.0126 13.1549 2.70654 13.6468 2.71777 13.784C2.73019 13.9359 2.76238 13.9939 2.88481 14.0847C2.99538 14.1667 3.49382 14.1667 4.49071 14.1667H15.5093C16.5062 14.1667 17.0046 14.1667 17.1152 14.0847C17.2376 13.9939 17.2698 13.9359 17.2822 13.784C17.2934 13.6468 16.9874 13.1549 16.3753 12.1712C15.6496 11.005 15 9.24184 15 6.66669Z"
      stroke="#747474"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Research = observer(() => {
  const { t } = useTranslation();
  const {
    socketStore,
    whaleSignalsStore,
    accountStore,
    ordersStore,
    blockchainStore,
    settingsStore,
    researchStore,
  } = useStores();
  const [w, AmplitudeWrapper] = useAmplitude([
    AmplitudeEvent.RESEARCH_SCREEN_VIEWED,
  ]);
  const { socket } = socketStore;
  const { network, isInit } = ordersStore;
  const { researchApproved } = settingsStore;
  const { refId, checkDone, isAuthCheckDone } = accountStore;
  const navigate = useNavigate();
  // const [network, setNetwork] = useState<ChainId>(ordersNetwork);
  const [search, setSearch] = useState<string>('');
  const debounceSearch = useDebounce<string>(search, 500);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [tokens, setTokens] = useState<HotToken[]>([]);
  const [isTokensLoaded, setIsTokensLoaded] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const [links, setLinks] = useState<InviteLink[]>([]);

  const isAddressValid = useMemo(() => {
    return ethRegex.test(search);
  }, [search]);

  usePersistentScroll({
    key: 'research-page',
    isReady: isTokensLoaded && isInit,
  });

  useEffect(() => {
    if (isAuthCheckDone)
      researchStore
        .getResearchInviteLinks()
        .then((response) => {
          if (Array.isArray(response)) {
            setLinks(response);
          } else {
            handleResponse(response);
          }
        })
        .catch((e) => {
          handleResponse(e.response);
        });
  }, [checkDone]);

  useEffect(() => {
    if (!isInit) return;
    setIsTokensLoaded(false);
    setTokens([]);
    researchStore
      .getHotTokens(network)
      .then((response) => {
        setIsTokensLoaded(true);
        if (Array.isArray(response)) {
          setTokens(response.slice(0, 20));
        } else {
          handleResponse(response);
        }
      })
      .catch((e) => {
        handleResponse(e.response);
        setIsTokensLoaded(true);
      });
    network === ChainId.ETHER && whaleSignalsStore.getWhaleSignalsPreview();
  }, [network, isInit]);

  useEffect(() => {
    if (search) {
      setIsSearching(true);
    }
    setIsInvalid(false);
  }, [search]);

  //@TODO: Replace it under store class during refactoring of account-store.ts
  useEffect(() => {
    enum Events {
      SUBSCRIBE_PRICE_UPDATE = 'SUBSCRIBE_PRICE_UPDATE',
      UNSUBSCRIBE_PRICE_UPDATE = 'UNSUBSCRIBE_PRICE_UPDATE',
    }

    const payload = {
      blockchain: network,
      pairAddresses: tokens.map(({ pairAddress }) => pairAddress),
    };

    const listener = (data: Record<string, string>) => {
      setTokens(
        tokens.map((tkn) => {
          if (
            tkn.pairAddress.toLowerCase() === data.pairAddress.toLowerCase()
          ) {
            tkn.priceChange24h = new BigNumber(
              calcAdjustedPercent(
                tkn.price,
                tkn.priceChange24h,
                data.tokenPriceInUsd,
              ),
            ).toNumber();
            tkn.price = new BigNumber(data.tokenPriceInUsd).toNumber();
            tkn.marketCap = new BigNumber(data.tokenMcap).toNumber();
          }

          return tkn;
        }),
      );
    };

    if (tokens.length) {
      socket.on(Events.SUBSCRIBE_PRICE_UPDATE, listener);

      socket.emit(Events.SUBSCRIBE_PRICE_UPDATE, payload);

      return () => {
        socket.emit(Events.UNSUBSCRIBE_PRICE_UPDATE);
        socket.off(Events.SUBSCRIBE_PRICE_UPDATE);
      };
    }
  }, [tokens.length]);

  useEffect(() => {
    if (debounceSearch && isAddressValid) {
      blockchainStore
        .getTokenDetails(debounceSearch)
        .then((response) => {
          setIsSearching(false);
          if (response && hasOwnProperty(response, 'pairAddress')) {
            navigate('/token-snipe/' + response.pairAddress);
          } else {
            setIsInvalid(true);
          }
        })
        .catch(() => {
          setIsInvalid(true);
          setIsSearching(false);
        });
    }
  }, [debounceSearch, isAddressValid]);

  useEffect(() => {
    if (search && search === debounceSearch && !isAddressValid) {
      setIsSearching(false);
      setIsInvalid(true);
    }
  }, [search, debounceSearch, isAddressValid]);

  const currentChainLink = useMemo(() => {
    return links.find((link) => link.blockchain.toString() === network);
  }, [links, network]);

  return (
    <div className="pt-3 pb-nav overflow-hidden">
      <div className="d-flex mb-3">
        <NetworkSelectionPill className="bg-semi-transparent-25" />
      </div>

      {network === ChainId.ETHER && <ResearchWhaleSignals />}

      <div className="d-flex align-items-center justify-content-between wd-100p ps-3">
        <span className="tx-semibold">🔥 {t('research.hot-list-24h')}</span>
        <AmplitudeWrapper
          className="mn-ht-55"
          eventInput={AmplitudeEvent.RESEARCH_INVITE_LINK_CLICKED}
        >
          <AlphaLink path={currentChainLink?.link}>
            <button className="btn p-3 d-flex align-items-center">
              {bell}
            </button>
          </AlphaLink>
        </AmplitudeWrapper>
      </div>

      {!isTokensLoaded && (
        <Cloner className="mb-3" count={3}>
          <ResearchItemSkeleton />
        </Cloner>
      )}

      {/*<div className={`input-group mt-2 mb-3 rounded-2 ${isInvalid ? 'is-invalid' : ''}`}>*/}
      {/*  <div className="input-group-text py-0 ht-35 pe-1 ps-2 border-0">*/}
      {/*    <img src={searchIcon} alt="search" width={16} height={16} />*/}
      {/*  </div>*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className={`form-control ht-35 ps-0 py-1 border-0 outline-none ${isInvalid ? 'tx-danger is-invalid' : 'tx-gray-400'}`}*/}
      {/*    value={search}*/}
      {/*    onChange={(e) => setSearch(e.target.value)}*/}
      {/*    placeholder="Token address"*/}
      {/*  />*/}
      {/*  {!!search && (*/}
      {/*    <div className="input-group-text py-0 ht-35 pe-2 ps-1 border-0">*/}
      {/*      <img src={cross} alt="Clear" width={16} height={16} onClick={() => setSearch('')} />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}

      {search && isSearching && (
        <div className="tx-center pt-5">
          <Preloader
            inline
            iconSize={64}
            className="d-flex flex-column align-items-center"
            textClass="d-block tx-semibold tx-28 mt-4"
            iconClass="d-block"
            text={t('order.searching-token')}
          />
        </div>
      )}

      {search && !isSearching && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">
            🔎
          </BadgeIcon>
          <h1 className="tx-28 mt-4">{t('order.empty-message')}</h1>
          <div className="tx-muted my-3">{t('common.nothing-found')}</div>
        </div>
      )}

      {!search && (
        <div>
          {tokens.map((item, index) =>
            network !== ChainId.TRON ? (
              <ResearchItem
                key={`research-token-${network}-${item.pairAddress}`}
                data={{ ...item, refId }}
                isLast={tokens.length - 1 === index}
                index={index}
                chain={network}
                chartLink={settingsStore.getChartLink(
                  network,
                  item.pairAddress,
                )}
                analyticsBot={settingsStore.analyticsBot}
              />
            ) : (
              <ResearchItemTron
                key={`research-token-${network}-${item.pairAddress}`}
                data={{ ...item, refId }}
                isLast={tokens.length - 1 === index}
                index={index}
                chain={network}
                chartLink={settingsStore.getChartLink(
                  network,
                  item.pairAddress,
                )}
                analyticsBot={settingsStore.analyticsBot}
              />
            ),
          )}
        </div>
      )}

      {isAuthCheckDone && researchApproved === false && (
        <ResearchApproveModal />
      )}
    </div>
  );
});

export default Research;
