import React, { useCallback, useMemo } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import useOrderData from '@hooks/useOrderData';
import checkGreenSecondary from '@assets/icons/checkmark-green-secondary.svg';
import checkRedSecondary from '@assets/icons/checkmark-red-secondary.svg';
import warningSecondary from '@assets/icons/warning-secondary.svg';
import clockGreenSecondary from '@assets/icons/clock-green-secondary.svg';
import clockRedSecondary from '@assets/icons/clock-red-secondary.svg';
import { OrderResponse } from '../../../../types/order/order.response';
import { LimitData } from '../../../../types/common.types';
import { roundTokenNumber } from '@helpers/calculations';

interface SolanaFlipOrderItemProps {
  item: OrderResponse;
  isLast: boolean;
  onClick: () => void;
  limitData: LimitData;
  nativePrice: number;
  isMcap: boolean;
}

const SolanaFlipOrderItem = ({
  item,
  onClick,
  limitData,
  nativePrice,
  isMcap,
}: SolanaFlipOrderItemProps) => {
  const { valueIn, valueOut } = item;

  const {
    isBuy,
    isError,
    errorText,
    isExecuting,
    isEstimated,
    dateShort,
    isCompleted,
    usdPrice,
    executionMcap: mCap,
    metadata,
  } = useOrderData(item, limitData, nativePrice);

  const formatDecimals = useCallback((value: BigNumber) => {
    const abs = value.abs();
    if (abs.isGreaterThan(100)) {
      return 0;
    }
    return abs.isLessThan(0.000001) ? 10 : 6;
  }, []);

  const trClassName = useMemo(() => {
    if (isError) {
      return 'bg-black error-msg';
    }
    const color = isBuy ? 'green' : 'red';
    return `bg-${color}-secondary tx-${color}-secondary`;
  }, [isBuy, isError]);

  const value = useMemo(() => {
    return isBuy ? valueIn : valueOut;
  }, [isBuy, valueIn, valueOut]);

  const amount = useMemo(() => {
    return new BigNumber(!isBuy ? valueIn : valueOut);
  }, [isBuy, valueIn, valueOut]);

  const tokensValue = useMemo(
    () => (isBuy && isExecuting ? null : roundTokenNumber(amount.valueOf())),
    [amount],
  );

  const icon = useMemo(() => {
    if (isError) {
      return warningSecondary;
    }
    if (isExecuting || isEstimated) {
      return isBuy? clockGreenSecondary : clockRedSecondary;
    }
    return isBuy ? checkGreenSecondary : checkRedSecondary;
  }, [isBuy, isError, isExecuting, isEstimated]);

  return (
    <tr className={`cur-pointer tx-12 ${trClassName}`} onClick={onClick}>
      <td colSpan={isError ? 4 : undefined} >
        {dateShort}
        {isError && ` - ${errorText}`}
      </td>
      {!isError && (
        <td>
          <FormattedNumber
            value={value || metadata?.sellSolValue}
            decimals={formatDecimals(
              new BigNumber(value || metadata?.sellSolValue || 0),
            )}
            useFormat={new BigNumber(
              value || metadata?.sellSolValue || 0,
            ).isGreaterThan(99000)}
            limitPlaceholderSize
            subZeros
          />
        </td>
      )}
      {!isError && (
        <td>
          <FormattedNumber
            value={tokensValue}
            decimals={formatDecimals(amount)}
            useFormat={amount.isGreaterThan(99000)}
            limitPlaceholderSize
            subZeros
          />
        </td>
      )}
      {!isError && (
        <td>
          {!isMcap && (
            <FormattedNumber
              value={usdPrice.isNaN() ? null : usdPrice.toNumber()}
              suffix="$"
              decimals={usdPrice.isLessThan(1) ? 4 : 0}
              limitPlaceholderSize
              subZeros
            />
          )}
          {isCompleted && isMcap && !mCap && <div>–</div>}
          {isMcap && !!mCap && (
            <FormattedNumber
              value={
                isExecuting || (isEstimated && new BigNumber(mCap).isNaN())
                  ? null
                  : new BigNumber(mCap || 0).toNumber()
              }
              suffix="$"
              decimals={new BigNumber(mCap || 0).isGreaterThan(99000) ? 2 : 0}
              useFormat={new BigNumber(mCap || 0).isGreaterThan(99000)}
              limitPlaceholderSize
            />
          )}
        </td>
      )}
      <td>
        <img src={icon} alt="status" width={16} />
      </td>
    </tr>
  );
};

export default SolanaFlipOrderItem;
