import React from 'react';
// @ts-ignore
import onboardingVideo from '@assets/videos/VP9.webm';
import { PageRoutes } from '../../constants';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Vibrutton from '@components/common/Vibrutton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import TermsAndConditionsNote from '@pages/Onboarding/components/TermsAndConditionsNote';
import LoginWithTelegram from '@pages/Onboarding/components/LoginWithTelegram';
import LoginWithGoogle from '@pages/Onboarding/components/LoginWithGoogle';

interface OnboardingProps {
  isCreateWallet?: boolean;
}

const Onboarding = observer(({isCreateWallet = false}: OnboardingProps) => {
  const { authStore } = useStores();
  const { isTMA } = authStore;
  // const isTMA = false;
  const { t } = useTranslation();
  const [w] = useAmplitude([
    AmplitudeEvent.ONBOARDING_SLIDE_VIEWED,
    { slide: 1 },
  ]);
  const navigate = useNavigate();

  // const login = useGoogleLogin({
  //   // onSuccess: tokenResponse => console.log(tokenResponse),
  //   ux_mode: 'redirect',
  //   // redirect_uri: `${ENV.API_URL}/api/v1/auth/login-google`,
  //   redirect_uri: `https://127.0.0.1/`,
  // });

  return (
    <div className="full-page bg-black d-flex flex-column justify-content-center overflow-auto py-0">
      <video src={onboardingVideo} autoPlay muted playsInline className="wd-100p mb-2" />

      {(isCreateWallet || isTMA) && (
        <>
          <Vibrutton
            className="btn btn-blue mx-3 text-center"
            onClick={w(
              () => navigate(PageRoutes.CREATE_WALLET),
              AmplitudeEvent.CREATE_WALLET_CLICKED,
              { slide: 1 },
            )}
          >
            {t('onboarding.create-wallet')}
          </Vibrutton>
        </>
      )}
      {!isCreateWallet && !isTMA && (
        <div className="mx-3 d-flex flex-row gap-2">
          <LoginWithTelegram />
          <LoginWithGoogle />
        </div>
      )}
      <div className="mt-1">
        <TermsAndConditionsNote />
      </div>
    </div>
  );
});

export default Onboarding;
