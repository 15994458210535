import React from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import CopyButton from '@components/common/CopyButton';
import LabelValueCard from '@components/common/LabelValueCard';
import { greaterThanOrEqual } from '@helpers/bignumber';
import { ResearchPairResponse } from '../../../types/research/research-pair.response';
import timeAgo, { getDateFnsLocale } from '@helpers/timeAgo';
import TokenLogo from '@components/common/TokenLogo';
import tokenNameClass from '@helpers/tokenNameClass';
import { useTranslation } from 'react-i18next';
import { getEllipsisTxt } from '@helpers/formatters';
import useShareToken from '@hooks/useShareToken';
import share from '@assets/icons/share.svg';
import { Blockchain } from 'types/enums';
import AlphaLink from '@components/common/AlphaLink';
import { useAmplitude, AmplitudeEvent } from '@hooks/useAmplitude';
import { ChainId } from '../../../constants';
import { useStores } from '@hooks/useStores';
import refresh from '@assets/icons/refresh.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const chart = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M17.3164 4.2666C16.7828 4.2666 16.3497 4.69967 16.3497 5.23327V18.7666C16.3497 19.3002 16.7828 19.7333 17.3164 19.7333H20.2164C20.75 19.7333 21.1831 19.3002 21.1831 18.7666V5.23327C21.1831 4.69967 20.75 4.2666 20.2164 4.2666H17.3164ZM10.5497 8.13327C10.0161 8.13327 9.58307 8.56633 9.58307 9.09993V18.7666C9.58307 19.3002 10.0161 19.7333 10.5497 19.7333H13.4497C13.9833 19.7333 14.4164 19.3002 14.4164 18.7666V9.09993C14.4164 8.56633 13.9833 8.13327 13.4497 8.13327H10.5497ZM3.78307 11.9999C3.24947 11.9999 2.81641 12.433 2.81641 12.9666V18.7666C2.81641 19.3002 3.24947 19.7333 3.78307 19.7333H6.68307C7.21667 19.7333 7.64974 19.3002 7.64974 18.7666V12.9666C7.64974 12.433 7.21667 11.9999 6.68307 11.9999H3.78307Z"
      fill="#F8F8F8"/>
</svg>

interface LimitOrdersTokenInfoProps {
  tokenDetails: ResearchPairResponse & {
    refId: string | null;
    address: string;
  };
  className?: string;
  shortView?: boolean;
  chartLink: string;
  analyticsLink: string;
  network?: ChainId | string;
}

const LimitOrdersTokenInfo = observer(({
  tokenDetails,
  className = 'card mb-3',
  chartLink,
  analyticsLink,
  shortView = false,
}: LimitOrdersTokenInfoProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [_, AmplitudeWrapper] = useAmplitude();
  const { tokenStore } = useStores();
  const { pathname } = useLocation();
  const { getTokenParamFromDetails } = tokenStore;
  const { blockchain, pairAddress, quoteToken, refId } = tokenDetails;

  const flipAvailable = blockchain === Blockchain.SOLANA;

  const handleShareToken = useShareToken();

  return (
    <div className={className}>
      <div className="d-flex flex-row justify-content-between align-items-center wd-100p">
        <TokenLogo
          logo={tokenDetails.logo}
          address={getTokenParamFromDetails('address', tokenDetails)}
          name={getTokenParamFromDetails('symbol', tokenDetails)}
          size={45}
          chain={blockchain}
          useName
        />

        <div className="wd-100p ms-2">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div
              className={tokenNameClass(
                getTokenParamFromDetails('name', tokenDetails),
              )}
            >
              {getTokenParamFromDetails('name', tokenDetails)}
            </div>

            <FormattedNumber
              value={tokenDetails.usdPrice}
              decimals={6}
              suffix="$"
              subZeros
              className="word-break mn-wd-max-content ms-2"
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            <AmplitudeWrapper
              eventInput={AmplitudeEvent.TOKEN_PAGE_CONTRACT_ADDRESS_COPIED}
              eventProperties={{ token: tokenDetails }}
            >
              <CopyButton
                text={getTokenParamFromDetails('address', tokenDetails)}
                className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none z-index-10 text-nowrap"
                iconClass="tx-muted ms-1"
              >
                CA:
                {getEllipsisTxt(
                  getTokenParamFromDetails('address', tokenDetails),
                  3,
                )}
              </CopyButton>
            </AmplitudeWrapper>
            {tokenDetails && (
              <CopyButton
                text={pairAddress}
                className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none z-index-10 ms-2 me-auto text-nowrap"
                iconClass="tx-muted ms-2"
              >
                P: {getEllipsisTxt(pairAddress || '', 3)}
              </CopyButton>
            )}
            <FormattedNumber
              value={tokenDetails?.priceChangePercent24h}
              withSign
              className={
                greaterThanOrEqual(tokenDetails?.priceChangePercent24h, 0)
                  ? 'tx-success'
                  : 'tx-danger'
              }
              postfix="%"
              hideNull
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3">
        <LabelValueCard
          label={t('common.liquidity')}
          value={tokenDetails.liquidity?.usd}
          unit="$"
        />
        <LabelValueCard
          label={t('common.volume-24')}
          value={tokenDetails.volume24h}
          unit="$"
        />
        <LabelValueCard
          label={t('common.mcap-shrt')}
          value={tokenDetails.mcap}
          unit="$"
        />
      </div>

      {!shortView && (
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2">
          <LabelValueCard
            label={t('order.age')}
            value={
              tokenDetails.pairCreatedAt
                ? timeAgo(
                    tokenDetails.pairCreatedAt,
                    undefined,
                    getDateFnsLocale(language),
                  )
                : t('common.na')
            }
            unit="$"
          />
          <LabelValueCard
            label={`${t('common.trades')} ${t('common.24h')}`}
            value={tokenDetails.trades24h}
          />
          <LabelValueCard label="DEX" value={tokenDetails.dex} unit="$" />
        </div>
      )}

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3 z-index-10">
        {flipAvailable && (
          <button
            className="btn btn-semi-10 py-2 px-0 tx-13 wd-100p d-flex align-items-center flex-row justify-content-center"
            onClick={() => navigate(`/solana-flip/${quoteToken.address}`, { state: { back: pathname } })}
          >
            <img src={refresh} alt="flip" width={16} />
            <span className="ms-2">Scalping</span>
          </button>
        )}
        {!flipAvailable && (
          <AmplitudeWrapper
            eventInput={AmplitudeEvent.CHART_TAB_CLICKED}
            eventProperties={{ token: tokenDetails }}
            className="wd-100p d-flex flex-grow-1"
          >
            <AlphaLink
              className="btn btn-semi-10 py-2 px-0 tx-13 text-decoration-none text-white wd-100p"
              path={chartLink}
            >
              <span className="me-2">📈</span>
              {t('common.graphics')}
            </AlphaLink>
          </AmplitudeWrapper>
        )}
        <AmplitudeWrapper
          eventInput={AmplitudeEvent.ANALYTIC_TAB_CLICKED}
          eventProperties={{ token: tokenDetails }}
          className="wd-100p d-flex flex-grow-1"
        >
          <AlphaLink
            className="wd-100p text-decoration-none text-white btn btn-primary-10 tx-13 tx-teal px-0 py-2 tx-13"
            path={analyticsLink}
          >
            <span className="me-2">🤖</span>
            {t('common.analytic')}
          </AlphaLink>
        </AmplitudeWrapper>

        <button
          className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
          onClick={() =>
            handleShareToken(
              blockchain as Blockchain,
              tokenDetails.address,
              refId!,
            )
          }
        >
          <img src={share} alt="more" width={20} />
        </button>
        {flipAvailable && (
          <AmplitudeWrapper
            eventInput={AmplitudeEvent.CHART_TAB_CLICKED}
            eventProperties={{ token: tokenDetails }}
            className="mn-wd-max-content d-flex flex-grow-1"
          >
            <AlphaLink
              className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
              path={chartLink}
            >
              {chart}
            </AlphaLink>
          </AmplitudeWrapper>
        )}
      </div>
    </div>
  );
});

export default LimitOrdersTokenInfo;
